import * as React from "react";
import {ApiFill} from "../../api";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";

export interface Props {
    fills: Array<ApiFill> | null;
}

interface State {

}

export default class FillsComponent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
    }

    render() {
        if (this.props.fills === null) {
            return (
                <div>loading fills...</div>
            );
        } else {
            const fills = this.props.fills.slice(0, 20).map(fill =>
                <TableRow key={fill.fill_id}>
                    <TableCell>{fill.exchange}</TableCell>
                    <TableCell>{fill.base_currency}</TableCell>
                    <TableCell>{fill.quote_currency}</TableCell>
                    <TableCell>{fill.side}</TableCell>
                    <TableCell>{fill.quantity}</TableCell>
                    <TableCell>{fill.price}</TableCell>
                </TableRow>
            );
            return (
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>exchange</TableCell>
                                <TableCell>base_currency</TableCell>
                                <TableCell>quote_currency</TableCell>
                                <TableCell>side</TableCell>
                                <TableCell>quantity</TableCell>
                                <TableCell>price</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {fills}
                        </TableBody>
                    </Table>
                </Paper>
            );
        }
    }

}
