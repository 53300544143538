import * as React from 'react';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_material from "@amcharts/amcharts4/themes/material";
import {throttle} from "lodash";

am4core.useTheme(am4themes_material);

export interface Props {
    asks: Array<[number, number]> | null;
    bids: Array<[number, number]> | null;
}

interface State {

}

class DepthChart extends React.Component<Props, State> {

    private updateThrottle = throttle(this.forceUpdate, 5000, {leading: true, trailing: true});
    private uuid = Math.random().toString(36);
    private chart?: am4charts.XYChart;
    // private xAxis: am4charts.CategoryAxis;
    // private first = true;

    constructor(props: Props) {
        super(props);
    }

    componentDidMount() {
        let chart = am4core.create(this.uuid, am4charts.XYChart);

        // Set up precision for numbers
        chart.numberFormatter.numberFormat = "#,###.########";

        // Create axes
        let xAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        xAxis.dataFields.category = "value";
        //xAxis.renderer.grid.template.location = 0;
        xAxis.renderer.minGridDistance = 50;
        xAxis.title.text = "Price";

        let yAxis = chart.yAxes.push(new am4charts.ValueAxis());
        yAxis.title.text = "Volume";

        // Create series
        let series = chart.series.push(new am4charts.StepLineSeries());
        series.dataFields.categoryX = "value";
        series.dataFields.valueY = "bidstotalvolume";
        series.strokeWidth = 2;
        series.stroke = am4core.color("#0f0");
        series.fill = series.stroke;
        series.fillOpacity = 0.1;
        series.tooltipText = "Bid: [bold]{categoryX}[/]\nTotal volume: [bold]{valueY}[/]\nVolume: [bold]{bidsvolume}[/]";

        let series2 = chart.series.push(new am4charts.StepLineSeries());
        series2.dataFields.categoryX = "value";
        series2.dataFields.valueY = "askstotalvolume";
        series2.strokeWidth = 2;
        series2.stroke = am4core.color("#f00");
        series2.fill = series2.stroke;
        series2.fillOpacity = 0.1;
        series2.tooltipText = "Ask: [bold]{categoryX}[/]\nTotal volume: [bold]{valueY}[/]\nVolume: [bold]{asksvolume}[/]";

        let series3 = chart.series.push(new am4charts.ColumnSeries());
        series3.dataFields.categoryX = "value";
        series3.dataFields.valueY = "bidsvolume";
        series3.strokeWidth = 0;
        series3.fill = am4core.color("#000");
        series3.fillOpacity = 0.2;

        let series4 = chart.series.push(new am4charts.ColumnSeries());
        series4.dataFields.categoryX = "value";
        series4.dataFields.valueY = "asksvolume";
        series4.strokeWidth = 0;
        series4.fill = am4core.color("#000");
        series4.fillOpacity = 0.2;

        // Add cursor
        chart.cursor = new am4charts.XYCursor();


        // chart.events.onAll(function (t) {
        //     console.log(t);
        // });

        chart.events.on("dataitemsvalidated", () => {
            // console.log("dfgdfgdfgdf");
            if (this.props.asks === null || this.props.bids === null) {
                return;
            }
            // const zoomStartIndex = this.props.bids.length - 20;
            // const zoomEndIndex = this.props.bids.length + 19;
            // console.log(zoomStartIndex + " - " + zoomEndIndex);
            const zoomStartCategory = this.props.bids[19][0].toString();
            const zoomEndCategory = this.props.asks[19][0].toString();
            // console.log(zoomStartCategory + " - " + zoomEndCategory);
            xAxis.zoomToCategories(zoomStartCategory, zoomEndCategory);
        });









        // chart.ignoreZoomed = false;
        // chart.addListener("zoomed", function(event) {
        //     if (chart.ignoreZoomed) {
        //         chart.ignoreZoomed = false;
        //         return;
        //     }
        //     console.log(event);
        //     chart.zoomStartIndex = event.startIndex;
        //     chart.zoomEndIndex = event.endIndex;
        // });
        //
        // chart.addListener("dataUpdated", function(event) {
        //     console.log(chart.zoomStartIndex);
        //     chart.zoomToIndexes(chart.zoomStartIndex, chart.zoomEndIndex);
        // });














        this.chart = chart;
        // this.xAxis = xAxis;
    }

    shouldComponentUpdate() {
        this.updateThrottle();
        return false;
    }

    componentWillUnmount() {
        this.updateThrottle.cancel();
        if (this.chart) {
            this.chart.dispose();
        }
    }

    componentDidUpdate(oldProps: Props) {
        if (this.chart === undefined || this.props.asks === null || this.props.bids === null) {
            return;
        }
        let chartData = [];
        let askTotalVolume = 0;
        for (let [price, quantity] of this.props.asks.slice(0, 49)) {
            askTotalVolume += quantity;
            chartData.push({
                value: price,
                asksvolume: quantity,
                askstotalvolume: askTotalVolume,
            });
        }
        let bidTotalVolume = 0;
        for (let [price, quantity] of this.props.bids.slice(0, 49)) {
            bidTotalVolume += quantity;
            chartData.unshift({
                value: price,
                bidsvolume: quantity,
                bidstotalvolume: bidTotalVolume,
            });
        }
        this.chart.data = chartData;
        // chart.ignoreZoomed = true;
        // this.chart.validateData();


        // this.xAxis.zoomToIndexes()
        // let min = Math.min(250, this.props.bids.length);
        // this.chart.zoomToIndexes(min - 20, min + 19);
        // this.first = false;

    }

    render() {
        return (
            <div id={this.uuid} style={{width: "100%", height: "500px"}}/>
        );
    }
}

export default DepthChart;
