import * as React from "react";

export interface Props {
    asks: Array<[number, number]> | null;
    bids: Array<[number, number]> | null;
}

interface State {

}

export default class BookComponent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
    }

    render() {
        if (this.props.asks === null || this.props.bids === null) {
            return (
                <div>loading book...</div>
            );
        } else {
            const asks = this.props.asks.slice(0, 20).map((ask, i) =>
                <li key={"asks_" + i}>{ask[0]} -> {ask[1]}</li>
            );
            const bids = this.props.bids.slice(0, 20).map((bid, i) =>
                <li key={"bids_" + i}>{bid[0]} -> {bid[1]}</li>
            );
            return (
                <table>
                    <thead>
                        <tr>
                            <th>asks</th>
                            <th>bids</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{asks}</td>
                            <td>{bids}</td>
                        </tr>
                    </tbody>
                </table>
            );
        }
    }

}
