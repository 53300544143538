import * as React from 'react';
import './index.css';
import {
    ChartingLibraryWidgetOptions,
    DatafeedConfiguration,
    ErrorCallback,
    Exchange,
    GetMarksCallback,
    IChartingLibraryWidget,
    IDatafeedChartApi,
    IExternalDatafeed,
    LanguageCode,
    LibrarySymbolInfo,
    ResolutionBackValues,
    ResolutionString,
    ResolveCallback,
    SearchSymbolsCallback,
    ServerTimeCallback,
    SubscribeBarsCallback,
    TimescaleMark,
    widget,
} from '../../charting_library/charting_library.min';
import {
    Bar,
    HistoryCallback,
    HistoryDepth,
    HistoryMetadata,
    OnReadyCallback
} from "../../charting_library/datafeed-api";
import {Mark} from "../../../public/charting_library/charting_library.min";
import {
    ApiCandlestickInterval,
    ApiFill,
    ApiFillSide,
    ApiOrder,
    ExchangesApi,
    FillsApi,
    MarketsApi,
    OrdersApi,
} from "../../api";
import {Auth} from 'aws-amplify';
import PublicSocket from "../socket/public_socket";

export interface ChartContainerProps {
    publicSocket: PublicSocket;
    exchange: string;
    base_currency: string;
    quote_currency: string;
}

export interface ChartContainerState {
}

function convertInterval(resolution: ResolutionString): ApiCandlestickInterval {
    if (resolution === '1') {
        return ApiCandlestickInterval._1M;
    } else if (resolution === '3') {
        return ApiCandlestickInterval._3M;
    } else if (resolution === '5') {
        return ApiCandlestickInterval._5M;
    } else if (resolution === '30') {
        return ApiCandlestickInterval._30M;
    } else if (resolution === '60') {
        return ApiCandlestickInterval._1H;
    } else if (resolution === 'D') {
        return ApiCandlestickInterval._1D;
    }
    throw 'unsupported resolution';
}

export class Derek implements IExternalDatafeed, IDatafeedChartApi {

    private readonly chart: CandleChart;

    constructor(chart: CandleChart) {
        this.chart = chart;
    }

    onReady(callback: OnReadyCallback): void {
    	console.log("onReady");

    	let api = new ExchangesApi();
    	api.getExchanges().then(exchanges => {

    	    let exchanges1:Exchange[] = [];

            exchanges.forEach(exchange => {
                let ee:Exchange = {
                    value: exchange.symbol,
                    name: exchange.symbol,
                    desc: exchange.name,
                };
                exchanges1.push(ee);
            });

            let configurationData:DatafeedConfiguration = {
                exchanges: exchanges1,
                symbols_types: [],
                supported_resolutions: ['1', '3', '5', '30', '60', '1D'],
                supports_marks: true,
                supports_timescale_marks: true,
                supports_time: true,
            };

            console.log(configurationData);
            callback(configurationData);
        });

    }

    calculateHistoryDepth(resolution: ResolutionString, resolutionBack: ResolutionBackValues, intervalBack: number): HistoryDepth | undefined {
        console.log("calculateHistoryDepth");
        return undefined;
    }

    getBars(symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, rangeStartDate: number, rangeEndDate: number, onResult: HistoryCallback, onError: ErrorCallback, isFirstCall: boolean): void {
        console.log("getBars");
        let parts = symbolInfo.full_name.split(/[:-]/);
        let exchange = parts[0];
        let base_currency = parts[1];
        let quote_currency = parts[2];
        let interval = convertInterval(resolution);
        let start = rangeStartDate * 1000;
        let end = rangeEndDate * 1000;

        let marketsApi = new MarketsApi();
        marketsApi.getCandles(exchange, base_currency, quote_currency, interval, start, end).then(ohlc_data => {

            let bars:Bar[] = [];

            for (let i = 0; i < ohlc_data.length; i += 1) {
                bars.push({
                    time: ohlc_data[i][0],
                    open: parseFloat(ohlc_data[i][1]),
                    high: parseFloat(ohlc_data[i][2]),
                    low: parseFloat(ohlc_data[i][3]),
                    close: parseFloat(ohlc_data[i][4]),
                    volume: parseFloat(ohlc_data[i][5]),
                });
            }

            let meta:HistoryMetadata = {
                noData: false,
                nextTime: null,
            };

            onResult(bars, meta);
        });

        Auth.currentSession().then(session => {
            let fillsApi = new FillsApi({
                accessToken: session.getAccessToken().getJwtToken()
            });
            fillsApi.getFills(exchange, base_currency, quote_currency).then(fills => {
                for (let fill of fills) {
                    this.chart.addFill(fill);
                }
            });
        });

    }

    getMarks(symbolInfo: LibrarySymbolInfo, from: number, to: number, onDataCallback: GetMarksCallback<Mark>, resolution: ResolutionString): void {
        console.log("getMarks");
    }

    getServerTime(callback: ServerTimeCallback): void {
        console.log("getServerTime");
    }

    getTimescaleMarks(symbolInfo: LibrarySymbolInfo, from: number, to: number, onDataCallback: GetMarksCallback<TimescaleMark>, resolution: ResolutionString): void {
        console.log("getTimescaleMarks");
    }

    resolveSymbol(symbolName: string, onResolve: ResolveCallback, onError: ErrorCallback): void {
        console.log("resolveSymbol");
        let parts = symbolName.split(/[:-]/);
        let exchange = parts[0];
        let base_currency = parts[1];
        let quote_currency = parts[2];

        let api = new MarketsApi();
        api.getMarket(exchange, base_currency, quote_currency).then(market => {

            let minmov = parseFloat((parseFloat(market.step_price) / 0.00000001).toFixed(8));

            let symbolInfo: LibrarySymbolInfo = {

                name: market.symbol,
                full_name: symbolName,
                base_name: undefined,

                ticker: symbolName,
                description: market.symbol,
                type: 'bitcoin',

                session: '24x7',

                exchange: market.exchange,
                listed_exchange: market.exchange,
                timezone: "America/Los_Angeles",

                pricescale: 100000000,

                minmov: minmov,
                fractional: false,

                minmove2: 0,

                has_intraday: true,

                supported_resolutions: ['1', '3', '5', '30', '60', '1D'],

                intraday_multipliers: ['1', '3', '5', '30', '60'],
                has_seconds: false,

                seconds_multipliers: [],
                has_daily: true,
                has_weekly_and_monthly: false,
                has_empty_bars: true,
                force_session_rebuild: true,
                has_no_volume: false,

                volume_precision: 8,
                data_status: 'streaming',

                expired: false,

                expiration_date: undefined,
                sector: 'sector ???',
                industry: 'industry ???',
                currency_code: market.quote_currency,
            };

            console.log(symbolInfo);
            onResolve(symbolInfo)
        });
    }

    searchSymbols(userInput: string, exchange: string, symbolType: string, onResult: SearchSymbolsCallback): void {
        console.log("searchSymbols");
    }

    subscribeBars(symbolInfo: LibrarySymbolInfo, resolution: ResolutionString, onTick: SubscribeBarsCallback, listenerGuid: string, onResetCacheNeededCallback: () => void): void {
        console.log("subscribeBars");
        let parts = symbolInfo.full_name.split(/[:-]/);
        let exchange = parts[0];
        let base_currency = parts[1];
        let quote_currency = parts[2];
        let interval = convertInterval(resolution);

        this.chart.props.publicSocket.subscribeCandles(exchange, base_currency, quote_currency, interval, (candlestick: Array<any>) => {
            onTick({
                time: candlestick[0],
                open: parseFloat(candlestick[1]),
                high: parseFloat(candlestick[2]),
                low: parseFloat(candlestick[3]),
                close: parseFloat(candlestick[4]),
                volume: parseFloat(candlestick[5]),
            });
        });
    }

    unsubscribeBars(listenerGuid: string): void {
        console.log("unsubscribeBars");
    }

}

class CandleChart extends React.Component<ChartContainerProps, ChartContainerState> {

	private tvWidget: IChartingLibraryWidget | null = null;

	public componentDidMount(): void {
		const widgetOptions: ChartingLibraryWidgetOptions = {
			symbol: this.props.exchange + ":" + this.props.base_currency + "-" + this.props.quote_currency,
			datafeed: new Derek(this),
			interval: "60",
			container_id: "tv_chart_container",
			library_path: "/charting_library/",
            locale: "en",
			disabled_features: ['use_localstorage_for_settings'],
			enabled_features: ['study_templates'],
			charts_storage_url: "https://saveload.tradingview.com",
			charts_storage_api_version: "1.1",
			client_id: "aobot.io",
			// user_id: "",
			fullscreen: false,
			autosize: true,
			studies_overrides: {},
		};

		const tvWidget = new widget(widgetOptions);
		this.tvWidget = tvWidget;

		tvWidget.onChartReady(() => {
            Auth.currentSession().then(session => {
                let ordersApi = new OrdersApi({
                    accessToken: session.getAccessToken().getJwtToken()
                });
                ordersApi.getOrders(this.props.exchange, this.props.base_currency, this.props.quote_currency).then(orders => {
                    for (let order of orders) {
                        this.addOrder(order);
                    }
                })
            });
        });

	}

	public componentWillUnmount(): void {
		if (this.tvWidget !== null) {
			this.tvWidget.remove();
			this.tvWidget = null;
		}
	}

	public render(): JSX.Element {
		return (
			<div
				id="tv_chart_container"
				className={ 'TVChartContainer' }
			/>
		);
	}

    addFill(fill: ApiFill) {
        let time = (fill.timestamp - (fill.timestamp % 60000)) / 1000;
        let price = parseFloat(fill.price);
        let quantity = fill.quantity;

        if (this.tvWidget == null) {
            return;
        }

        if (fill.side == ApiFillSide.BUY) {
            this.tvWidget.chart().createShape(
                {
                    time: time,
                    price: price
                },
                {
                    shape: 'arrow_up',
                    text: quantity,
                    lock: true,
                    zOrder: 'top',
                }
            );
        }

        if (fill.side == ApiFillSide.SELL) {
            this.tvWidget.chart().createShape(
                {
                    time: time,
                    price: price
                },
                {
                    shape: 'arrow_down',
                    text: quantity,
                    lock: true,
                    zOrder: 'top',
                }
            );
        }
    }

    addOrder(order: ApiOrder) {
        if (this.tvWidget == null) {
            return;
        }
        console.log(order);
        this.tvWidget.chart().createOrderLine({disableUndo: true})
            .setBodyBackgroundColor("#0000FF")
            .setText(order.side.toString())
            .setQuantity(order.quantity)
            .setPrice(parseFloat(order.limit_price))
            .onCancel(order, function(order) {
                console.log("onCancel");
                Auth.currentSession().then(session => {
                    let ordersApi = new OrdersApi({
                        accessToken: session.getAccessToken().getJwtToken()
                    });
                    ordersApi.cancelOrder(order.order_id);
                });
            });
    }

}

export default CandleChart;
