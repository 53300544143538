import * as React from "react";
import {ApiNotification} from "../../api";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";

export interface Props {
    notifications: Array<ApiNotification> | null;
}

interface State {

}

export default class NotificationsComponent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
    }

    render() {
        if (this.props.notifications === null) {
            return (
                <div>loading notifications...</div>
            );
        } else {
            const notifications = this.props.notifications.slice(0, 20).map(notification =>
                <TableRow key={notification.notification_id}>
                    <TableCell>{notification.message}</TableCell>
                </TableRow>
            );
            return (
                <Paper>
                    Notifications
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>message</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {notifications}
                        </TableBody>
                    </Table>
                </Paper>
            );
        }
    }

}
