import * as React from "react";
import {ApiOrder} from "../../api";
import {Link} from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";

export interface Props {
    openOrders: Array<ApiOrder> | null;
}

interface State {

}

export default class OpenOrdersComponent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
    }

    render() {
        if (this.props.openOrders === null) {
            return (
                <div>loading open orders...</div>
            );
        } else {
            const orders = this.props.openOrders.map(order =>
                <TableRow key={order.order_id}>
                    <TableCell><Link to={"/market/" + order.exchange + "/" + order.base_currency + "/" + order.quote_currency}>trade</Link></TableCell>
                    <TableCell>{order.exchange}</TableCell>
                    <TableCell>{order.base_currency}</TableCell>
                    <TableCell>{order.quote_currency}</TableCell>
                    <TableCell>{order.limit_price}</TableCell>
                </TableRow>
            );
            return (
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>trade</TableCell>
                                <TableCell>exchange</TableCell>
                                <TableCell>baseCurrency</TableCell>
                                <TableCell>quoteCurrency</TableCell>
                                <TableCell>limit</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orders}
                        </TableBody>
                    </Table>
                </Paper>
            );
        }
    }

}
