import {Auth} from 'aws-amplify';
import {ApiAlert, ApiBalance, ApiFill, ApiNotification, ApiOrder} from "../../api";

export default class UserSocket {

    private userSocket?: WebSocket;
    private subscriptions = new Map<string, Set<(message: any) => any>>();

    constructor() {
        this.connect();
    }

    public subscribeAlert(callback: (message: ApiAlert) => any) {
        this.subscribe('ALERT', callback);
    }

    public subscribeBalance(callback: (message: ApiBalance) => any) {
        this.subscribe('BALANCE', callback);
    }

    public subscribeFill(callback: (message: ApiFill) => any) {
        this.subscribe('FILL', callback);
    }

    public subscribeNotification(callback: (message: ApiNotification) => any) {
        this.subscribe('NOTIFICATION', callback);
    }

    public subscribeOrder(callback: (message: ApiOrder) => any) {
        this.subscribe('ORDER', callback);
    }

    public unsubscribeAlert(callback: (message: ApiAlert) => any) {
        this.unsubscribe('ALERT', callback);
    }

    public unsubscribeBalance(callback: (message: ApiBalance) => any) {
        this.unsubscribe('BALANCE', callback);
    }

    public unsubscribeFill(callback: (message: ApiFill) => any) {
        this.unsubscribe('FILL', callback);
    }

    public unsubscribeNotification(callback: (message: ApiNotification) => any) {
        this.unsubscribe('NOTIFICATION', callback);
    }

    public unsubscribeOrder(callback: (message: ApiOrder) => any) {
        this.unsubscribe('ORDER', callback);
    }

    private subscribe(event_type: string, callback: (message: any) => any) {
        let subscription = this.subscriptions.get(event_type);
        if (subscription === undefined) {
            subscription = new Set();
            this.subscriptions.set(event_type, subscription);
        }
        subscription.add(callback);
    }

    private unsubscribe(event_type: string, callback: (message: any) => any) {
        let subscription = this.subscriptions.get(event_type);
        if (subscription) {
            subscription.delete(callback);
        }
    }

    private connect() {
        Auth.currentSession().then(session => {
            this.userSocket = new WebSocket("wss://socket.aobot.io/v1/account/" + session.getAccessToken().getJwtToken());
            this.userSocket.onopen = event => this.onOpen(event);
            this.userSocket.onmessage = event => this.onMessage(event);
            this.userSocket.onclose = event => this.onClose(event);
        });
    }

    private onOpen(event?: Event) {
        console.log("user socket onOpen");
    }

    private onMessage(event: MessageEvent) {
        const parse = JSON.parse(event.data);
        const subscription = this.subscriptions.get(parse.event_type);
        if (subscription) {
            for (const callback of subscription) {
                callback(parse.event_data);
            }
        }
    }

    private onClose(event: Event) {
        console.log("user socket onClose");
    }

}
