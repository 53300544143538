import * as React from 'react';
import {Auth} from 'aws-amplify';
import {
    AlertsApi,
    ApiAlert,
    ApiAlertStatus,
    ApiBalance,
    ApiFill,
    ApiMarket,
    ApiOrder,
    ApiOrderBook,
    ApiOrderStatus,
    ApiTicker,
    ApiTrade,
    BalancesApi,
    FillsApi,
    MarketsApi,
    OrdersApi
} from "api"
import TickerComponent from "./ticker";
import BookComponent from "./book";
import DepthChart from "../depth";
import TradeComponent from "./trades";
import CandleChart from "../candle";
import PublicSocket from "../socket/public_socket";
import UserSocket from "../socket/user_socket";
import OpenOrdersComponent from "./open_orders";
import AlertsComponent from "./alerts";
import FillsComponent from "./fills";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

export interface Props {
    publicSocket: PublicSocket;
    userSocket: UserSocket;
    exchange: string;
    base_currency: string;
    quote_currency: string;
}

interface State {
    asks: Array<[number, number]> | null;
    bids: Array<[number, number]> | null;
    market: ApiMarket | null;
    trades: Array<ApiTrade> | null;
    ticker: ApiTicker | null;

    alerts: Array<ApiAlert> | null;
    balanceBase: ApiBalance | null;
    balanceQuote: ApiBalance | null;
    fills: Array<ApiFill> | null;
    openOrders: Array<ApiOrder> | null;

    tab1: number;
    tab2: number;
    tab3: number,
}

export default class MarketComponent extends React.Component<Props, State> {

    private marketsApi = new MarketsApi();

    constructor(props: Props) {
        super(props);
        this.state = {
            asks: null,
            bids: null,
            market: null,
            trades: null,
            ticker: null,

            alerts: null,
            balanceBase: null,
            balanceQuote: null,
            fills: null,
            openOrders: null,

            tab1: 0,
            tab2: 0,
            tab3: 0,
        };
    }

    private version = 0;
    private asks = new Map<number, number>();
    private bids = new Map<number, number>();

    private loadOrderBook() {
        this.marketsApi.getBook(this.props.exchange, this.props.base_currency, this.props.quote_currency).then(apiOrderBook =>  {
            this.version = apiOrderBook.version;
            this.asks.clear();
            this.bids.clear();
            for (const ask of apiOrderBook.asks) {
                const price = parseFloat(ask[0]);
                const quantity = parseFloat(ask[1]);
                this.asks.set(price, quantity);
            }
            for (const bid of apiOrderBook.bids) {
                const price = parseFloat(bid[0]);
                const quantity = parseFloat(bid[1]);
                this.bids.set(price, quantity);
            }
        });
    }

    private bookUpdate = (apiOrderBook: ApiOrderBook) => {
        if (apiOrderBook.version > this.version + 1) {
            console.log("newer version number, loading");
            this.loadOrderBook();
            return;
        }
        if (apiOrderBook.version < this.version + 1) {
            console.log("older version number, skipping");
            return;
        }
        this.version = apiOrderBook.version;
        for (const ask of apiOrderBook.asks) {
            const price = parseFloat(ask[0]);
            const quantity = parseFloat(ask[1]);
            if (quantity > 0) {
                this.asks.set(price, quantity);
            } else {
                this.asks.delete(price);
            }
        }
        for (const bid of apiOrderBook.bids) {
            const price = parseFloat(bid[0]);
            const quantity = parseFloat(bid[1]);
            if (quantity > 0) {
                this.bids.set(price, quantity);
            } else {
                this.bids.delete(price);
            }
        }
        const sortedAsks = Array.from(this.asks.entries()).sort((a, b) => a[0] - b[0]).slice(0, 250);
        const sortedBids = Array.from(this.bids.entries()).sort((a, b) => a[0] - b[0]).reverse().slice(0, 250);

        this.setState({
            asks: sortedAsks,
            bids: sortedBids,
        });
    };

    private tickerUpdate = (apiTicker: ApiTicker) => {
        this.setState({
            ticker: apiTicker
        })
    };

    private trades = new Array<ApiTrade>();

    private addTrade = (apiTrade: ApiTrade) => {
        this.trades.unshift(apiTrade);
        this.trades = this.trades.slice(0, 100);
        this.setState({
            trades: this.trades,
        });
    };

    componentDidMount() {
        this.props.publicSocket.subscribeBook(
            this.props.exchange,
            this.props.base_currency,
            this.props.quote_currency,
            this.bookUpdate,
        );
        this.props.publicSocket.subscribeTicker(
            this.props.exchange,
            this.props.base_currency,
            this.props.quote_currency,
            this.tickerUpdate,
        );
        this.props.publicSocket.subscribeTrades(
            this.props.exchange,
            this.props.base_currency,
            this.props.quote_currency,
            this.addTrade,
        );

        this.loadOrderBook();
        this.marketsApi.getMarket(this.props.exchange, this.props.base_currency, this.props.quote_currency).then(apiMarket => {
            this.setState({
                market: apiMarket,
            });
        });

        this.marketsApi.getTicker(this.props.exchange, this.props.base_currency, this.props.quote_currency).then(apiTicker => {
            this.setState({
                ticker: apiTicker,
            });
        });

        this.marketsApi.getTrades(this.props.exchange, this.props.base_currency, this.props.quote_currency).then(apiTrades => {
            this.trades = apiTrades.slice(0, 100);
            this.setState({
                trades: this.trades,
            });
        });

        this.props.userSocket.subscribeAlert(alert => {
            if (this.state.alerts === null) {
                return;
            }
            if (alert.exchange !== this.props.exchange || alert.base_currency !== this.props.base_currency || alert.quote_currency !== this.props.quote_currency) {
                return;
            }
            if (alert.status === ApiAlertStatus.DONE || alert.status === ApiAlertStatus.CANCELLED) {
                this.setState({
                    alerts: this.state.alerts.filter(a => a.alert_id !== alert.alert_id),
                });
            } else {
                this.setState({
                    alerts: [alert].concat(this.state.alerts),
                });
            }
        });

        this.props.userSocket.subscribeBalance(balance => {
            if (balance.exchange === this.props.exchange && balance.currency === this.props.base_currency) {
                this.setState({
                    balanceBase: balance,
                });
            } else if (balance.exchange === this.props.exchange && balance.currency === this.props.quote_currency) {
                this.setState({
                    balanceQuote: balance,
                });
            }
        });

        this.props.userSocket.subscribeFill(fill => {
            if (this.state.fills === null) {
                return;
            }
            if (fill.exchange !== this.props.exchange || fill.base_currency !== this.props.base_currency || fill.quote_currency !== this.props.quote_currency) {
                return;
            }
            const fills = this.state.fills.filter(f => f.fill_id !== fill.fill_id);
            fills.unshift(fill);
            this.setState({
                fills: fills,
            });
        });

        this.props.userSocket.subscribeOrder(order => {
            if (this.state.openOrders === null) {
                return;
            }
            if (order.exchange !== this.props.exchange || order.base_currency !== this.props.base_currency || order.quote_currency !== this.props.quote_currency) {
                return;
            }
            if (order.status === ApiOrderStatus.EXECUTED || order.status === ApiOrderStatus.CANCELED || order.status === ApiOrderStatus.REJECTED) {
                this.setState({
                    openOrders: this.state.openOrders.filter(o => o.order_id !== order.order_id),
                });
            } else if (order.status === ApiOrderStatus.NEW) {
                this.setState({
                    openOrders: [order].concat(this.state.openOrders),
                });
            } else {
                this.setState({
                    openOrders: this.state.openOrders.map(o => o.order_id === order.order_id ? order : o),
                });
            }
        });

        Auth.currentSession().then(session => {
            let alertsApi = new AlertsApi({
                accessToken: session.getAccessToken().getJwtToken()
            });
            alertsApi.getAlerts(this.props.exchange, this.props.base_currency, this.props.quote_currency).then(alerts => {
                this.setState({
                    alerts: alerts,
                });
            });
            const balancesApi = new BalancesApi({
                accessToken: session.getAccessToken().getJwtToken()
            });
            balancesApi.getBalances(this.props.exchange, this.props.base_currency).then(balances => {
                this.setState({
                    balanceBase: balances[0], // todo: new API to get a single balance? this feels hackey
                });
            });
            balancesApi.getBalances(this.props.exchange, this.props.quote_currency).then(balances => {
                this.setState({
                    balanceQuote: balances[0], // todo: new API to get a single balance? this feels hackey
                });
            });
            let fillsApi = new FillsApi({
                accessToken: session.getAccessToken().getJwtToken()
            });
            fillsApi.getFills(this.props.exchange, this.props.base_currency, this.props.quote_currency).then(fills => {
                this.setState({
                    fills: fills,
                });
            });
            let ordersApi = new OrdersApi({
                accessToken: session.getAccessToken().getJwtToken()
            });
            ordersApi.getOrders(this.props.exchange, this.props.base_currency, this.props.quote_currency).then(orders => {
                this.setState({
                    openOrders: orders,
                });
            });
        });


    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.exchange !== this.props.exchange || prevProps.base_currency !== this.props.base_currency || prevProps.quote_currency !== this.props.quote_currency) {
            console.log("componentDidUpdate start");
            console.log(prevProps);
            console.log(this.props);
            console.log("componentDidUpdate end");

            this.props.publicSocket.unsubscribeBook(
                prevProps.exchange,
                prevProps.base_currency,
                prevProps.quote_currency,
                this.bookUpdate,
            );
            this.props.publicSocket.unsubscribeTicker(
                prevProps.exchange,
                prevProps.base_currency,
                prevProps.quote_currency,
                this.tickerUpdate,
            );
            this.props.publicSocket.unsubscribeTrades(
                prevProps.exchange,
                prevProps.base_currency,
                prevProps.quote_currency,
                this.addTrade,
            );

            this.setState({
                asks: null,
                bids: null,
                market: null,
                trades: null,
                ticker: null,

                alerts: null,
                balanceBase: null,
                balanceQuote: null,
                fills: null,
                openOrders: null,
            });

            this.componentDidMount();
        }
    }

    // componentWillReceiveProps(oldProps: Props) {
    //     console.log("componentWillReceiveProps start");
    //     console.log(oldProps);
    //     console.log(this.props);
    //     console.log("componentWillReceiveProps end");
    // }

    componentWillUnmount() {
        this.props.publicSocket.unsubscribeBook(
            this.props.exchange,
            this.props.base_currency,
            this.props.quote_currency,
            this.bookUpdate,
        );
        this.props.publicSocket.unsubscribeTicker(
            this.props.exchange,
            this.props.base_currency,
            this.props.quote_currency,
            this.tickerUpdate,
        );
        this.props.publicSocket.unsubscribeTrades(
            this.props.exchange,
            this.props.base_currency,
            this.props.quote_currency,
            this.addTrade,
        );
    }

    tabChange1 = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({
            tab1: newValue
        });
    };

    tabChange2 = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({
            tab2: newValue
        });
    };

    tabChange3 = (event: React.ChangeEvent<{}>, newValue: number) => {
        this.setState({
            tab3: newValue
        });
    };

    render() {
        return (
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper>
                        <h1>{this.props.exchange}/{this.props.base_currency}/{this.props.quote_currency}</h1>
                        <TickerComponent ticker={this.state.ticker} />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <h3>{this.props.base_currency}: {this.state.balanceBase ? this.state.balanceBase.balance : 'loading...'}</h3>
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <h3>{this.props.quote_currency}: {this.state.balanceQuote ? this.state.balanceQuote.balance : 'loading...'}</h3>
                    </Paper>
                </Grid>
                <Grid item xs={9}>
                    <Paper>
                        <Tabs value={this.state.tab3} onChange={this.tabChange3}>
                            <Tab label="Candle" />
                            <Tab label="Depth" />
                        </Tabs>
                        {this.state.tab3 === 0 && <CandleChart publicSocket={this.props.publicSocket} exchange={this.props.exchange} base_currency={this.props.base_currency} quote_currency={this.props.quote_currency} />}
                        {this.state.tab3 === 1 && <DepthChart asks={this.state.asks} bids={this.state.bids} />}
                    </Paper>
                </Grid>
                <Grid item xs={3}>
                    <Paper>
                        <Tabs value={this.state.tab2} onChange={this.tabChange2}>
                            <Tab label="Trades" />
                            <Tab label="Book" />
                        </Tabs>
                        {this.state.tab2 === 0 && <TradeComponent trades={this.state.trades} />}
                        {this.state.tab2 === 1 && <BookComponent asks={this.state.asks} bids={this.state.bids} />}
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper>
                        <Tabs value={this.state.tab1} onChange={this.tabChange1}>
                            <Tab label="Orders" />
                            <Tab label="Fills" />
                            <Tab label="Alerts" />
                        </Tabs>
                        {this.state.tab1 === 0 && <OpenOrdersComponent openOrders={this.state.openOrders} />}
                        {this.state.tab1 === 1 && <FillsComponent fills={this.state.fills} />}
                        {this.state.tab1 === 2 && <AlertsComponent alerts={this.state.alerts} />}
                    </Paper>
                </Grid>
            </Grid>
        );
    }

}
