import * as React from "react";
import {Auth} from 'aws-amplify';
import {
    AlertsApi,
    ApiAlert, ApiAlertStatus,
    ApiBalance,
    ApiFill,
    ApiNotification,
    ApiOrder,
    ApiTicker,
    BalancesApi,
    FillsApi,
    MarketsApi,
    NotificationsApi,
    OrdersApi
} from "../../api";
import UserSocket from "../socket/user_socket";
import PublicSocket from "../socket/public_socket";
import OpenOrdersComponent from "./open_orders";
import BalancesComponent from "./balances";
import FillsComponent from "./fills";
import NotificationsComponent from "./notifications";
import AlertsComponent from "./alerts";

export class Tt {

    private _balance: ApiBalance;
    private _ticker?: ApiTicker;

    constructor(balance: ApiBalance) {
        this._balance = balance;
    }

    get balance(): ApiBalance {
        return this._balance;
    }

    set balance(value: ApiBalance) {
        this._balance = value;
    }

    get ticker(): ApiTicker | undefined {
        return this._ticker;
    }

    public setApiTicker = (apiTicker: ApiTicker) => {
        this._ticker = apiTicker;
    };

    get btc(): number {
        if (this._balance.currency === 'BTC') {
            return parseFloat(this._balance.balance);
        }
        if (this.ticker) {
            if (this._balance.currency === 'USD' || this._balance.currency === 'USDT') {
                return parseFloat(this._balance.balance) / parseFloat(this.ticker.last);
            } else {
                return parseFloat(this.ticker.last) * parseFloat(this._balance.balance);
            }
        }
        return 0;
    }

}

export interface Props {
    publicSocket: PublicSocket;
    userSocket: UserSocket;
}

interface State {
    alerts: Array<ApiAlert> | null;
    balances: Array<Tt> | null;
    fills: Array<ApiFill> | null;
    notifications: Array<ApiNotification> | null,
    openOrders: Array<ApiOrder> | null;
}

export default class UserComponent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            alerts: null,
            balances: null,
            fills: null,
            notifications: null,
            openOrders: null,
        };
    }

    componentDidMount() {
        this.props.userSocket.subscribeAlert((apiAlert: ApiAlert) => {
            console.log(apiAlert)
        });
        Auth.currentSession().then(session => {

            let alertsApi = new AlertsApi({
                accessToken: session.getAccessToken().getJwtToken()
            });
            alertsApi.getAlerts().then(alerts => {
                this.setState({
                    alerts: alerts,
                });
            });

            const marketsApi = new MarketsApi();
            const balancesApi = new BalancesApi({
                accessToken: session.getAccessToken().getJwtToken()
            });
            balancesApi.getBalances().then(balances => {
                this.setState({
                    balances: balances.map(b => {
                        const tt = new Tt(b);
                        if (b.currency == 'USD' || b.currency == 'USDT') {
                            this.props.publicSocket.subscribeTicker(b.exchange, "BTC", b.currency, tt.setApiTicker);
                            this.props.publicSocket.subscribeTicker(b.exchange, "BTC", b.currency, this.hack);
                            marketsApi.getTicker(b.exchange, "BTC", b.currency).then(apiTicker => {
                                tt.setApiTicker(apiTicker);
                            });
                        } else {
                            this.props.publicSocket.subscribeTicker(b.exchange, b.currency, "BTC", tt.setApiTicker);
                            this.props.publicSocket.subscribeTicker(b.exchange, b.currency, "BTC", this.hack);
                            marketsApi.getTicker(b.exchange, b.currency, "BTC").then(apiTicker => {
                                tt.setApiTicker(apiTicker);
                            });
                        }
                        return tt;
                    }),
                });
            });

            let fillsApi = new FillsApi({
                accessToken: session.getAccessToken().getJwtToken()
            });
            fillsApi.getFills().then(fills => {
                this.setState({
                    fills: fills,
                });
            });

            const notificationsApi = new NotificationsApi({
                accessToken: session.getAccessToken().getJwtToken()
            });
            notificationsApi.getNotifications().then(apiNotifications => {
                console.log(apiNotifications);
                this.setState({
                    notifications: apiNotifications,
                });
            });

            const ordersApi = new OrdersApi({
                accessToken: session.getAccessToken().getJwtToken()
            });
            ordersApi.getOrders().then(apiOrders => {
                console.log(apiOrders);
                this.setState({
                    openOrders: apiOrders,
                });
            });

            this.props.userSocket.subscribeAlert(alert => {
                if (this.state.alerts === null) {
                    return;
                }
                if (alert.status === ApiAlertStatus.DONE || alert.status === ApiAlertStatus.CANCELLED) {
                    this.setState({
                        alerts: this.state.alerts.filter(a => a.alert_id !== alert.alert_id),
                    });
                } else {
                    this.setState({
                        alerts: [alert].concat(this.state.alerts),
                    });
                }
            });

            this.props.userSocket.subscribeBalance(balance => {

            });

            this.props.userSocket.subscribeFill(fill => {
                if (this.state.fills === null) {
                    return;
                }
                const fills = this.state.fills.filter(f => f.fill_id !== fill.fill_id);
                fills.unshift(fill);
                this.setState({
                    fills: fills,
                });
            });

            this.props.userSocket.subscribeNotification(notification => {

            });

            this.props.userSocket.subscribeOrder(order => {

            });




        });
    }

    componentWillUnmount() {

    }

    public hack = (apiTicker: ApiTicker) => {
        this.forceUpdate();
    };

    render() {
        return (
            <div>
                <AlertsComponent alerts={this.state.alerts} />
                <BalancesComponent balances={this.state.balances} />
                <FillsComponent fills={this.state.fills} />
                <NotificationsComponent notifications={this.state.notifications} />
                <OpenOrdersComponent openOrders={this.state.openOrders} />
            </div>
        );
    }

}
