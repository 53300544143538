import React from 'react';

import {withAuthenticator} from 'aws-amplify-react'
import {Auth} from 'aws-amplify';

import {BrowserRouter, Route, RouteComponentProps, Switch} from "react-router-dom";
import MarketComponent from "components/test/market";
import UserComponent from "components/test/user";
import UserSocket from "./components/socket/user_socket";
import PublicSocket from "./components/socket/public_socket";

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { createStyles, makeStyles, withStyles, Theme } from '@material-ui/core/styles';
import { Link, LinkProps } from 'react-router-dom';

let authOptions = {
  "userPoolId": "us-east-1_DKi5hrnhN",
  "userPoolWebClientId": "26stpkhjatmk090d55oqukiu8e",
};

Auth.configure(authOptions);

interface RouteInfo {
  exchange: string;
  base_currency: string;
  quote_currency: string;
}

interface State {

}

interface Props extends RouteComponentProps<RouteInfo> {

}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        flexGrow: 1,
      },
      menuButton: {
        marginRight: theme.spacing(2),
      },
      title: {
        flexGrow: 1,
      },
    }),
);

function ButtonAppBar() {
  const classes = useStyles();

  return (
      <div className={classes.root}>
        <AppBar position="static">
          <Toolbar>
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="Menu">
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              News
            </Typography>
            <Button color="inherit" component={Link} to="/account">Login</Button>
          </Toolbar>
        </AppBar>
      </div>
  );
}

class App extends React.Component<Props, State> {

  private publicSocket: PublicSocket;
  private userSocket: UserSocket;

  constructor(props: Props) {
    super(props);
    this.publicSocket = new PublicSocket();
    this.userSocket = new UserSocket();
  }

  public render() {
    return (
        <BrowserRouter>
          <ButtonAppBar/>
          <Switch>
            <Route path="/market/:exchange/:base_currency/:quote_currency" render={(props) => <MarketComponent publicSocket={this.publicSocket} userSocket={this.userSocket} exchange={props.match.params.exchange} base_currency={props.match.params.base_currency} quote_currency={props.match.params.quote_currency}/>}/>
            <Route path='/account' render={() => <UserComponent userSocket={this.userSocket} publicSocket={this.publicSocket}/>} />
          </Switch>
        </BrowserRouter>
    );
  }
}

export default withAuthenticator(App, false);
