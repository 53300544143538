import {
    ApiCandlestickInterval,
    ApiOrderBook,
    ApiTicker,
    ApiTrade,
    EventType,
    SocketMessage,
    SubscribeMessage,
    SubscribeType
} from "api";
import {Subscription} from "./subscription";

export default class PublicSocket {

    private publicSocket?: WebSocket;
    private subscriptions = new Map<string, Subscription>();

    constructor() {
        this.connect();
    }

    public subscribeBook(exchange: string, base_currency: string, quote_currency: string, callback: (message: ApiOrderBook) => any) {
        this.subscribe(exchange, base_currency, quote_currency, EventType.BOOK, callback);
    }

    public subscribeTicker(exchange: string, base_currency: string, quote_currency: string, callback: (message: ApiTicker) => any) {
        this.subscribe(exchange, base_currency, quote_currency, EventType.TICKER, callback);
    }

    public subscribeTrades(exchange: string, base_currency: string, quote_currency: string, callback: (message: ApiTrade) => any) {
        this.subscribe(exchange, base_currency, quote_currency, EventType.TRADE, callback);
    }

    public subscribeCandles(exchange: string, base_currency: string, quote_currency: string, interval: ApiCandlestickInterval, callback: (message: Array<any>) => any) {
        switch (interval) {
            case ApiCandlestickInterval._1M:
                this.subscribe(exchange, base_currency, quote_currency, EventType.CANDLE1M, callback);
                break;
            case ApiCandlestickInterval._3M:
                this.subscribe(exchange, base_currency, quote_currency, EventType.CANDLE3M, callback);
                break;
            case ApiCandlestickInterval._5M:
                this.subscribe(exchange, base_currency, quote_currency, EventType.CANDLE5M, callback);
                break;
            case ApiCandlestickInterval._30M:
                this.subscribe(exchange, base_currency, quote_currency, EventType.CANDLE30M, callback);
                break;
            case ApiCandlestickInterval._1H:
                this.subscribe(exchange, base_currency, quote_currency, EventType.CANDLE1H, callback);
                break;
            case ApiCandlestickInterval._1D:
                this.subscribe(exchange, base_currency, quote_currency, EventType.CANDLE1D, callback);
                break;
        }
    }

    private subscribe(exchange: string, base_currency: string, quote_currency: string, event_type: EventType, callback: (message: any) => any) {
        const key = exchange + "/" + base_currency + "/" + quote_currency + "/" + event_type;
        let subscription = this.subscriptions.get(key);
        if (subscription === undefined) {
            subscription = new Subscription(exchange, base_currency, quote_currency, event_type);
            this.subscriptions.set(key, subscription);
        }
        subscription.addCallback(callback);
        this.send(SubscribeType.SUBSCRIBE, exchange, base_currency, quote_currency, event_type);
    }

    public unsubscribeBook(exchange: string, base_currency: string, quote_currency: string, callback: (message: ApiOrderBook) => any) {
        this.unsubscribe(exchange, base_currency, quote_currency, EventType.BOOK, callback);
    }

    public unsubscribeTicker(exchange: string, base_currency: string, quote_currency: string, callback: (message: ApiTicker) => any) {
        this.unsubscribe(exchange, base_currency, quote_currency, EventType.TICKER, callback);
    }

    public unsubscribeTrades(exchange: string, base_currency: string, quote_currency: string, callback: (message: ApiTrade) => any) {
        this.unsubscribe(exchange, base_currency, quote_currency, EventType.TRADE, callback);
    }

    public unsubscribeCandles(exchange: string, base_currency: string, quote_currency: string, interval: ApiCandlestickInterval, callback: (message: Array<any>) => any) {
        switch (interval) {
            case ApiCandlestickInterval._1M:
                this.unsubscribe(exchange, base_currency, quote_currency, EventType.CANDLE1M, callback);
                break;
            case ApiCandlestickInterval._3M:
                this.unsubscribe(exchange, base_currency, quote_currency, EventType.CANDLE3M, callback);
                break;
            case ApiCandlestickInterval._5M:
                this.unsubscribe(exchange, base_currency, quote_currency, EventType.CANDLE5M, callback);
                break;
            case ApiCandlestickInterval._30M:
                this.unsubscribe(exchange, base_currency, quote_currency, EventType.CANDLE30M, callback);
                break;
            case ApiCandlestickInterval._1H:
                this.unsubscribe(exchange, base_currency, quote_currency, EventType.CANDLE1H, callback);
                break;
            case ApiCandlestickInterval._1D:
                this.unsubscribe(exchange, base_currency, quote_currency, EventType.CANDLE1D, callback);
                break;
        }
    }

    private unsubscribe(exchange: string, base_currency: string, quote_currency: string, event_type: EventType, callback: (message: any) => any) {
        const key = exchange + "/" + base_currency + "/" + quote_currency + "/" + event_type;
        let subscription = this.subscriptions.get(key);
        if (subscription) {
            subscription.removeCallback(callback);
            this.send(SubscribeType.UNSUBSCRIBE, exchange, base_currency, quote_currency, event_type);
        }
    }

    private connect() {
        this.publicSocket = new WebSocket("wss://socket.aobot.io/v1/public");
        this.publicSocket.onopen = event => this.onOpen(event);
        this.publicSocket.onmessage = event => this.onMessage(event);
        this.publicSocket.onclose = event => this.onClose(event);
    }

    private onOpen(event?: Event) {
        console.log("public socket onOpen");
        for (const subscription of this.subscriptions.values()) {
            this.send(SubscribeType.SUBSCRIBE, subscription.exchange, subscription.base_currency, subscription.quote_currency, subscription.event_type);
        }
    }

    private onMessage(event: MessageEvent) {
        const socketMessage: SocketMessage = JSON.parse(event.data);
        const key = socketMessage.exchange + "/" + socketMessage.base_currency + "/" + socketMessage.quote_currency + "/" + socketMessage.event_type;
        const subscription = this.subscriptions.get(key);
        if (subscription) {
            subscription.publish(socketMessage.event_data);
        }
    }

    private onClose(event: Event) {
        console.log("public socket onClose");
        this.connect();
    }

    private send(type: SubscribeType, exchange: string, base_currency: string, quote_currency: string, event_type: EventType) {
        if (this.publicSocket && this.publicSocket.readyState === WebSocket.OPEN) {
            const msg: SubscribeMessage = {
                type: type,
                exchange: exchange,
                base_currency: base_currency,
                quote_currency: quote_currency,
                event_type: event_type,
            };
            this.publicSocket.send(JSON.stringify(msg));
        }
    }

}
