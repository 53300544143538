import * as React from "react";
import {ApiTicker} from "../../api";

export interface Props {
    ticker: ApiTicker | null;
}

interface State {

}

export default class TickerComponent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
    }

    render() {
        if (this.props.ticker === null) {
            return (
                <div>loading ticker...</div>
            );
        } else {
            return (
                <div>{this.props.ticker.last}</div>
            );
        }
    }

}
