import {EventType} from "api";

export class Subscription {

    private readonly _exchange: string;
    private readonly _base_currency: string;
    private readonly _quote_currency: string;
    private readonly _event_type: EventType;

    private readonly callbacks = new Set<(message: any) => any>();

    constructor(exchange: string, base_currency: string, quote_currency: string, event_type: EventType) {
        this._exchange = exchange;
        this._base_currency = base_currency;
        this._quote_currency = quote_currency;
        this._event_type = event_type;
    }

    get exchange(): string {
        return this._exchange;
    }

    get base_currency(): string {
        return this._base_currency;
    }

    get quote_currency(): string {
        return this._quote_currency;
    }

    get event_type(): EventType {
        return this._event_type;
    }

    public addCallback(callback: (message: any) => any) {
        this.callbacks.add(callback)
    }

    public removeCallback(callback: (message: any) => any) {
        this.callbacks.delete(callback)
    }

    public publish(message: any) {
        for (const callback of this.callbacks) {
            callback(message);
        }
    }

}
