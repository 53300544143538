import * as React from "react";
import {ApiTrade} from "../../api";

export interface Props {
    trades: Array<ApiTrade> | null;
}

interface State {

}

export default class TradeComponent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
    }

    render() {
        if (this.props.trades === null) {
            return (
                <div>loading trades...</div>
            );
        } else {
            const trades = this.props.trades.slice(0, 20).map(trade =>
                <tr key={trade.trade_id}>
                    <td>{trade.trade_id}</td>
                    <td>{trade.price}</td>
                    <td>{trade.quantity}</td>
                </tr>
            );
            return (
                <table>
                    <thead>
                        <tr>
                            <th>id</th>
                            <th>price</th>
                            <th>quantity</th>
                        </tr>
                    </thead>
                    <tbody>
                        {trades}
                    </tbody>
                </table>
            );
        }
    }

}
