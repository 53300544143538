import * as React from "react";
import {ApiAlert} from "../../api";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";

export interface Props {
    alerts: Array<ApiAlert> | null;
}

interface State {

}

export default class AlertsComponent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
    }

    render() {
        if (this.props.alerts === null) {
            return (
                <div>loading alerts...</div>
            );
        } else {
            const alerts = this.props.alerts.slice(0, 20).map(alert =>
                <TableRow key={alert.alert_id}>
                    <TableCell>{alert.exchange}</TableCell>
                    <TableCell>{alert.base_currency}</TableCell>
                    <TableCell>{alert.quote_currency}</TableCell>
                    <TableCell>{alert.type}</TableCell>
                    <TableCell>{alert.price}</TableCell>
                    <TableCell>{alert.note}</TableCell>
                </TableRow>
            );
            return (
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>exchange</TableCell>
                                <TableCell>base_currency</TableCell>
                                <TableCell>quote_currency</TableCell>
                                <TableCell>type</TableCell>
                                <TableCell>price</TableCell>
                                <TableCell>note</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {alerts}
                        </TableBody>
                    </Table>
                </Paper>
            );
        }
    }

}
