/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * AoBot API
 * AoBot API docs
 *
 * OpenAPI spec version: 1.0.0
 * Contact: derek@aobot.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "https://api.aobot.io/v1".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *  
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AddAccountRequest
 */
export interface AddAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof AddAccountRequest
     */
    exchange?: string;
    /**
     * 
     * @type {string}
     * @memberof AddAccountRequest
     */
    key?: string;
    /**
     * 
     * @type {string}
     * @memberof AddAccountRequest
     */
    secret?: string;
    /**
     * 
     * @type {string}
     * @memberof AddAccountRequest
     */
    passphrase?: string;
}

/**
 * 
 * @export
 * @interface AddAlertRequest
 */
export interface AddAlertRequest {
    /**
     * 
     * @type {string}
     * @memberof AddAlertRequest
     */
    exchange?: string;
    /**
     * 
     * @type {string}
     * @memberof AddAlertRequest
     */
    base_currency?: string;
    /**
     * 
     * @type {string}
     * @memberof AddAlertRequest
     */
    quote_currency?: string;
    /**
     * 
     * @type {string}
     * @memberof AddAlertRequest
     */
    price?: string;
    /**
     * 
     * @type {string}
     * @memberof AddAlertRequest
     */
    note?: string;
}

/**
 * 
 * @export
 * @interface AddOrderRequest
 */
export interface AddOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof AddOrderRequest
     */
    exchange?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrderRequest
     */
    base_currency?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrderRequest
     */
    quote_currency?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrderRequest
     */
    client_order_id?: string;
    /**
     * 
     * @type {ApiOrderSide}
     * @memberof AddOrderRequest
     */
    side?: ApiOrderSide;
    /**
     * 
     * @type {ApiOrderStop}
     * @memberof AddOrderRequest
     */
    stop?: ApiOrderStop;
    /**
     * 
     * @type {ApiOrderType}
     * @memberof AddOrderRequest
     */
    type?: ApiOrderType;
    /**
     * 
     * @type {string}
     * @memberof AddOrderRequest
     */
    stop_price?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrderRequest
     */
    limit_price?: string;
    /**
     * 
     * @type {string}
     * @memberof AddOrderRequest
     */
    quantity?: string;
    /**
     * 
     * @type {ApiTimeInForce}
     * @memberof AddOrderRequest
     */
    time_in_force?: ApiTimeInForce;
    /**
     * 
     * @type {number}
     * @memberof AddOrderRequest
     */
    cancel_after?: number;
    /**
     * 
     * @type {AddOrderRequest}
     * @memberof AddOrderRequest
     */
    stop_loss_order?: AddOrderRequest;
    /**
     * 
     * @type {AddOrderRequest}
     * @memberof AddOrderRequest
     */
    take_profit_order?: AddOrderRequest;
}

/**
 * 
 * @export
 * @interface AddOrderRequest1
 */
export interface AddOrderRequest1 {
    /**
     * 
     * @type {string}
     * @memberof AddOrderRequest1
     */
    exchange?: string;
}

/**
 * 
 * @export
 * @interface AddStudyRequest
 */
export interface AddStudyRequest {
    /**
     * 
     * @type {string}
     * @memberof AddStudyRequest
     */
    exchange: string;
    /**
     * 
     * @type {string}
     * @memberof AddStudyRequest
     */
    base_currency: string;
    /**
     * 
     * @type {string}
     * @memberof AddStudyRequest
     */
    quote_currency: string;
    /**
     * 
     * @type {string}
     * @memberof AddStudyRequest
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof AddStudyRequest
     */
    description?: string;
}

/**
 * 
 * @export
 * @interface AddStudyShapeRequest
 */
export interface AddStudyShapeRequest {
    /**
     * 
     * @type {string}
     * @memberof AddStudyShapeRequest
     */
    client_shape_id: string;
    /**
     * 
     * @type {Array<ApiShapePoint>}
     * @memberof AddStudyShapeRequest
     */
    points: Array<ApiShapePoint>;
    /**
     * 
     * @type {string}
     * @memberof AddStudyShapeRequest
     */
    shape: string;
    /**
     * 
     * @type {string}
     * @memberof AddStudyShapeRequest
     */
    text?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof AddStudyShapeRequest
     */
    overrides?: { [key: string]: string; };
}

/**
 * 
 * @export
 * @interface ApiAccount
 */
export interface ApiAccount {
    /**
     * 
     * @type {string}
     * @memberof ApiAccount
     */
    exchange?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAccount
     */
    key?: string;
    /**
     * 
     * @type {boolean}
     * @memberof ApiAccount
     */
    tracking_enabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof ApiAccount
     */
    trading_enabled?: boolean;
}

/**
 * 
 * @export
 * @interface ApiAlert
 */
export interface ApiAlert {
    /**
     * 
     * @type {string}
     * @memberof ApiAlert
     */
    alert_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAlert
     */
    exchange?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAlert
     */
    base_currency?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAlert
     */
    quote_currency?: string;
    /**
     * 
     * @type {ApiAlertStatus}
     * @memberof ApiAlert
     */
    status?: ApiAlertStatus;
    /**
     * 
     * @type {ApiAlertType}
     * @memberof ApiAlert
     */
    type?: ApiAlertType;
    /**
     * 
     * @type {string}
     * @memberof ApiAlert
     */
    price?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiAlert
     */
    note?: string;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApiAlertStatus {
    WAITING = 'WAITING',
    CANCELLED = 'CANCELLED',
    DONE = 'DONE'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApiAlertType {
    ABOVE = 'ABOVE',
    BELOW = 'BELOW'
}

/**
 * 
 * @export
 * @interface ApiBalance
 */
export interface ApiBalance {
    /**
     * 
     * @type {string}
     * @memberof ApiBalance
     */
    exchange: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBalance
     */
    currency: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBalance
     */
    balance: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBalance
     */
    available: string;
    /**
     * 
     * @type {string}
     * @memberof ApiBalance
     */
    unavailable: string;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApiCandlestickInterval {
    _1M = '1M',
    _3M = '3M',
    _5M = '5M',
    _30M = '30M',
    _1H = '1H',
    _1D = '1D'
}

/**
 * 
 * @export
 * @interface ApiError
 */
export interface ApiError {
    /**
     * 
     * @type {number}
     * @memberof ApiError
     */
    code?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiError
     */
    message?: string;
}

/**
 * 
 * @export
 * @interface ApiExchange
 */
export interface ApiExchange {
    /**
     * 
     * @type {string}
     * @memberof ApiExchange
     */
    symbol: string;
    /**
     * 
     * @type {string}
     * @memberof ApiExchange
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ApiExchange
     */
    market_count?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiExchange
     */
    volume24h?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiExchange
     */
    volume7d?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiExchange
     */
    volume30d?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiExchange
     */
    twitter?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiExchange
     */
    website?: string;
}

/**
 * 
 * @export
 * @interface ApiFill
 */
export interface ApiFill {
    /**
     * 
     * @type {string}
     * @memberof ApiFill
     */
    fill_id: string;
    /**
     * 
     * @type {string}
     * @memberof ApiFill
     */
    order_id: string;
    /**
     * 
     * @type {string}
     * @memberof ApiFill
     */
    exchange: string;
    /**
     * 
     * @type {string}
     * @memberof ApiFill
     */
    base_currency: string;
    /**
     * 
     * @type {string}
     * @memberof ApiFill
     */
    quote_currency: string;
    /**
     * 
     * @type {number}
     * @memberof ApiFill
     */
    timestamp: number;
    /**
     * 
     * @type {ApiFillSide}
     * @memberof ApiFill
     */
    side: ApiFillSide;
    /**
     * 
     * @type {string}
     * @memberof ApiFill
     */
    price: string;
    /**
     * 
     * @type {string}
     * @memberof ApiFill
     */
    quantity: string;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApiFillSide {
    BUY = 'BUY',
    SELL = 'SELL'
}

/**
 * 
 * @export
 * @interface ApiMarket
 */
export interface ApiMarket {
    /**
     * 
     * @type {string}
     * @memberof ApiMarket
     */
    exchange: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMarket
     */
    base_currency: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMarket
     */
    quote_currency: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMarket
     */
    symbol: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMarket
     */
    min_price: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMarket
     */
    max_price: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMarket
     */
    step_price: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMarket
     */
    min_quantity: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMarket
     */
    max_quantity: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMarket
     */
    step_quantity: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMarket
     */
    open_24h?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMarket
     */
    high_24h?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMarket
     */
    low_24h?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMarket
     */
    base_volume_24h?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMarket
     */
    quote_volume_24h?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiMarket
     */
    trade_count_24h?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiMarket
     */
    last?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMarket
     */
    bid?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiMarket
     */
    ask?: string;
}

/**
 * 
 * @export
 * @interface ApiNotification
 */
export interface ApiNotification {
    /**
     * 
     * @type {string}
     * @memberof ApiNotification
     */
    notification_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiNotification
     */
    message?: string;
}

/**
 * 
 * @export
 * @interface ApiOrder
 */
export interface ApiOrder {
    /**
     * 
     * @type {string}
     * @memberof ApiOrder
     */
    order_id: string;
    /**
     * 
     * @type {string}
     * @memberof ApiOrder
     */
    client_order_id?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiOrder
     */
    exchange: string;
    /**
     * 
     * @type {string}
     * @memberof ApiOrder
     */
    base_currency: string;
    /**
     * 
     * @type {string}
     * @memberof ApiOrder
     */
    quote_currency: string;
    /**
     * 
     * @type {ApiOrderStatus}
     * @memberof ApiOrder
     */
    status: ApiOrderStatus;
    /**
     * 
     * @type {number}
     * @memberof ApiOrder
     */
    timestamp?: number;
    /**
     * 
     * @type {ApiOrderSide}
     * @memberof ApiOrder
     */
    side: ApiOrderSide;
    /**
     * 
     * @type {ApiOrderStop}
     * @memberof ApiOrder
     */
    stop?: ApiOrderStop;
    /**
     * 
     * @type {ApiOrderType}
     * @memberof ApiOrder
     */
    type?: ApiOrderType;
    /**
     * 
     * @type {string}
     * @memberof ApiOrder
     */
    stop_price?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiOrder
     */
    limit_price: string;
    /**
     * 
     * @type {string}
     * @memberof ApiOrder
     */
    quantity: string;
    /**
     * 
     * @type {string}
     * @memberof ApiOrder
     */
    executed_quantity?: string;
}

/**
 * 
 * @export
 * @interface ApiOrderBook
 */
export interface ApiOrderBook {
    /**
     * 
     * @type {number}
     * @memberof ApiOrderBook
     */
    version: number;
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof ApiOrderBook
     */
    asks: Array<Array<string>>;
    /**
     * 
     * @type {Array<Array<string>>}
     * @memberof ApiOrderBook
     */
    bids: Array<Array<string>>;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApiOrderSide {
    BUY = 'BUY',
    SELL = 'SELL'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApiOrderStatus {
    NEW = 'NEW',
    PENDING = 'PENDING',
    SUBMITTED = 'SUBMITTED',
    PARTIALLYEXECUTED = 'PARTIALLY_EXECUTED',
    CANCELED = 'CANCELED',
    REJECTED = 'REJECTED',
    EXECUTED = 'EXECUTED'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApiOrderStop {
    LOSS = 'LOSS',
    ENTRY = 'ENTRY'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApiOrderType {
    LIMIT = 'LIMIT',
    MARKET = 'MARKET'
}

/**
 * 
 * @export
 * @interface ApiShapePoint
 */
export interface ApiShapePoint {
    /**
     * 
     * @type {number}
     * @memberof ApiShapePoint
     */
    time?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiShapePoint
     */
    price?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiShapePoint
     */
    channel?: string;
}

/**
 * 
 * @export
 * @interface ApiStats
 */
export interface ApiStats {
    /**
     * 
     * @type {string}
     * @memberof ApiStats
     */
    open?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiStats
     */
    high?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiStats
     */
    low?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiStats
     */
    base_volume?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiStats
     */
    quote_volume?: string;
}

/**
 * 
 * @export
 * @interface ApiStudy
 */
export interface ApiStudy {
    /**
     * 
     * @type {string}
     * @memberof ApiStudy
     */
    study_id: string;
    /**
     * 
     * @type {string}
     * @memberof ApiStudy
     */
    exchange: string;
    /**
     * 
     * @type {string}
     * @memberof ApiStudy
     */
    base_currency: string;
    /**
     * 
     * @type {string}
     * @memberof ApiStudy
     */
    quote_currency: string;
    /**
     * 
     * @type {string}
     * @memberof ApiStudy
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ApiStudy
     */
    description?: string;
}

/**
 * 
 * @export
 * @interface ApiStudyShape
 */
export interface ApiStudyShape {
    /**
     * 
     * @type {string}
     * @memberof ApiStudyShape
     */
    study_id: string;
    /**
     * 
     * @type {string}
     * @memberof ApiStudyShape
     */
    client_shape_id: string;
    /**
     * 
     * @type {Array<ApiShapePoint>}
     * @memberof ApiStudyShape
     */
    points: Array<ApiShapePoint>;
    /**
     * 
     * @type {string}
     * @memberof ApiStudyShape
     */
    shape: string;
    /**
     * 
     * @type {string}
     * @memberof ApiStudyShape
     */
    text?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof ApiStudyShape
     */
    overrides?: { [key: string]: string; };
}

/**
 * 
 * @export
 * @interface ApiTicker
 */
export interface ApiTicker {
    /**
     * 
     * @type {string}
     * @memberof ApiTicker
     */
    open_24h?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTicker
     */
    high_24h?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTicker
     */
    low_24h?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTicker
     */
    base_volume_24h?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTicker
     */
    quote_volume_24h?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiTicker
     */
    trade_count_24h?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiTicker
     */
    last: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTicker
     */
    bid?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTicker
     */
    ask?: string;
}

/**
 * 
 * @export
 * @interface ApiTickerMessage
 */
export interface ApiTickerMessage {
    /**
     * 
     * @type {string}
     * @memberof ApiTickerMessage
     */
    exchange?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTickerMessage
     */
    base_currency?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTickerMessage
     */
    quote_currency?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTickerMessage
     */
    open_24h?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTickerMessage
     */
    high_24h?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTickerMessage
     */
    low_24h?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTickerMessage
     */
    base_volume_24h?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTickerMessage
     */
    quote_volume_24h?: string;
    /**
     * 
     * @type {number}
     * @memberof ApiTickerMessage
     */
    trade_count_24h?: number;
    /**
     * 
     * @type {string}
     * @memberof ApiTickerMessage
     */
    last?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTickerMessage
     */
    bid?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTickerMessage
     */
    ask?: string;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApiTimeInForce {
    GTC = 'GTC',
    GTT = 'GTT',
    IOC = 'IOC',
    FOK = 'FOK'
}

/**
 * 
 * @export
 * @interface ApiTrade
 */
export interface ApiTrade {
    /**
     * 
     * @type {string}
     * @memberof ApiTrade
     */
    trade_id: string;
    /**
     * 
     * @type {number}
     * @memberof ApiTrade
     */
    timestamp: number;
    /**
     * 
     * @type {ApiTradeSide}
     * @memberof ApiTrade
     */
    side: ApiTradeSide;
    /**
     * 
     * @type {string}
     * @memberof ApiTrade
     */
    price: string;
    /**
     * 
     * @type {string}
     * @memberof ApiTrade
     */
    quantity: string;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum ApiTradeSide {
    BUY = 'BUY',
    SELL = 'SELL'
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum EventType {
    BOOK = 'BOOK',
    CANDLE1M = 'CANDLE_1M',
    CANDLE3M = 'CANDLE_3M',
    CANDLE5M = 'CANDLE_5M',
    CANDLE30M = 'CANDLE_30M',
    CANDLE1H = 'CANDLE_1H',
    CANDLE1D = 'CANDLE_1D',
    TICKER = 'TICKER',
    TRADE = 'TRADE',
    SUBSCRIBE = 'SUBSCRIBE',
    UNSUBSCRIBE = 'UNSUBSCRIBE'
}

/**
 * 
 * @export
 * @interface SocketMessage
 */
export interface SocketMessage {
    /**
     * 
     * @type {EventType}
     * @memberof SocketMessage
     */
    event_type?: EventType;
    /**
     * 
     * @type {string}
     * @memberof SocketMessage
     */
    exchange?: string;
    /**
     * 
     * @type {string}
     * @memberof SocketMessage
     */
    base_currency?: string;
    /**
     * 
     * @type {string}
     * @memberof SocketMessage
     */
    quote_currency?: string;
    /**
     * 
     * @type {any}
     * @memberof SocketMessage
     */
    event_data?: any;
}

/**
 * 
 * @export
 * @interface SubscribeMessage
 */
export interface SubscribeMessage {
    /**
     * 
     * @type {SubscribeType}
     * @memberof SubscribeMessage
     */
    type?: SubscribeType;
    /**
     * 
     * @type {EventType}
     * @memberof SubscribeMessage
     */
    event_type?: EventType;
    /**
     * 
     * @type {string}
     * @memberof SubscribeMessage
     */
    exchange?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscribeMessage
     */
    base_currency?: string;
    /**
     * 
     * @type {string}
     * @memberof SubscribeMessage
     */
    quote_currency?: string;
}

/**
 * 
 * @export
 * @enum {string}
 */
export enum SubscribeType {
    SUBSCRIBE = 'SUBSCRIBE',
    UNSUBSCRIBE = 'UNSUBSCRIBE'
}

/**
 * 
 * @export
 * @interface UpdateAccountRequest
 */
export interface UpdateAccountRequest {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAccountRequest
     */
    tracking_enabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAccountRequest
     */
    trading_enabled?: boolean;
}

/**
 * 
 * @export
 * @interface UpdateAlertRequest
 */
export interface UpdateAlertRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertRequest
     */
    price?: string;
}

/**
 * 
 * @export
 * @interface UpdateOrderRequest
 */
export interface UpdateOrderRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderRequest
     */
    price?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOrderRequest
     */
    quantity?: string;
}


/**
 * AccountsApi - fetch parameter creator
 * @export
 */
export const AccountsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {AddAccountRequest} add_account_request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAccount(add_account_request: AddAccountRequest, options: any = {}): FetchArgs {
            // verify required parameter 'add_account_request' is not null or undefined
            if (add_account_request === null || add_account_request === undefined) {
                throw new RequiredError('add_account_request','Required parameter add_account_request was null or undefined when calling addAccount.');
            }
            const localVarPath = `/accounts`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("OAuth2", ["openid"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddAccountRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(add_account_request || {}) : (add_account_request || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete account for exchange
         * @summary delete account for exchange
         * @param {string} exchange symbol of the exchange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount(exchange: string, options: any = {}): FetchArgs {
            // verify required parameter 'exchange' is not null or undefined
            if (exchange === null || exchange === undefined) {
                throw new RequiredError('exchange','Required parameter exchange was null or undefined when calling deleteAccount.');
            }
            const localVarPath = `/accounts/{exchange}`
                .replace(`{${"exchange"}}`, encodeURIComponent(String(exchange)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("OAuth2", ["openid"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get account for exchange
         * @summary get account for exchange
         * @param {string} exchange symbol of the exchange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(exchange: string, options: any = {}): FetchArgs {
            // verify required parameter 'exchange' is not null or undefined
            if (exchange === null || exchange === undefined) {
                throw new RequiredError('exchange','Required parameter exchange was null or undefined when calling getAccount.');
            }
            const localVarPath = `/accounts/{exchange}`
                .replace(`{${"exchange"}}`, encodeURIComponent(String(exchange)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("OAuth2", ["openid"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccounts(options: any = {}): FetchArgs {
            const localVarPath = `/accounts`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("OAuth2", ["openid"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update account for exchange
         * @summary update account for exchange
         * @param {string} exchange symbol of the exchange
         * @param {UpdateAccountRequest} update_account_request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount(exchange: string, update_account_request: UpdateAccountRequest, options: any = {}): FetchArgs {
            // verify required parameter 'exchange' is not null or undefined
            if (exchange === null || exchange === undefined) {
                throw new RequiredError('exchange','Required parameter exchange was null or undefined when calling updateAccount.');
            }
            // verify required parameter 'update_account_request' is not null or undefined
            if (update_account_request === null || update_account_request === undefined) {
                throw new RequiredError('update_account_request','Required parameter update_account_request was null or undefined when calling updateAccount.');
            }
            const localVarPath = `/accounts/{exchange}`
                .replace(`{${"exchange"}}`, encodeURIComponent(String(exchange)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("OAuth2", ["openid"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateAccountRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(update_account_request || {}) : (update_account_request || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountsApi - functional programming interface
 * @export
 */
export const AccountsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {AddAccountRequest} add_account_request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAccount(add_account_request: AddAccountRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiAccount> {
            const localVarFetchArgs = AccountsApiFetchParamCreator(configuration).addAccount(add_account_request, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * delete account for exchange
         * @summary delete account for exchange
         * @param {string} exchange symbol of the exchange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount(exchange: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiAccount> {
            const localVarFetchArgs = AccountsApiFetchParamCreator(configuration).deleteAccount(exchange, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * get account for exchange
         * @summary get account for exchange
         * @param {string} exchange symbol of the exchange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(exchange: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiAccount> {
            const localVarFetchArgs = AccountsApiFetchParamCreator(configuration).getAccount(exchange, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccounts(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ApiAccount>> {
            const localVarFetchArgs = AccountsApiFetchParamCreator(configuration).getAccounts(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * update account for exchange
         * @summary update account for exchange
         * @param {string} exchange symbol of the exchange
         * @param {UpdateAccountRequest} update_account_request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount(exchange: string, update_account_request: UpdateAccountRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiAccount> {
            const localVarFetchArgs = AccountsApiFetchParamCreator(configuration).updateAccount(exchange, update_account_request, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AccountsApi - factory interface
 * @export
 */
export const AccountsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {AddAccountRequest} add_account_request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAccount(add_account_request: AddAccountRequest, options?: any) {
            return AccountsApiFp(configuration).addAccount(add_account_request, options)(fetch, basePath);
        },
        /**
         * delete account for exchange
         * @summary delete account for exchange
         * @param {string} exchange symbol of the exchange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAccount(exchange: string, options?: any) {
            return AccountsApiFp(configuration).deleteAccount(exchange, options)(fetch, basePath);
        },
        /**
         * get account for exchange
         * @summary get account for exchange
         * @param {string} exchange symbol of the exchange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccount(exchange: string, options?: any) {
            return AccountsApiFp(configuration).getAccount(exchange, options)(fetch, basePath);
        },
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAccounts(options?: any) {
            return AccountsApiFp(configuration).getAccounts(options)(fetch, basePath);
        },
        /**
         * update account for exchange
         * @summary update account for exchange
         * @param {string} exchange symbol of the exchange
         * @param {UpdateAccountRequest} update_account_request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount(exchange: string, update_account_request: UpdateAccountRequest, options?: any) {
            return AccountsApiFp(configuration).updateAccount(exchange, update_account_request, options)(fetch, basePath);
        },
    };
};

/**
 * AccountsApi - object-oriented interface
 * @export
 * @class AccountsApi
 * @extends {BaseAPI}
 */
export class AccountsApi extends BaseAPI {
    /**
     * get a list of api keys
     * @summary get a list of api keys
     * @param {AddAccountRequest} add_account_request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public addAccount(add_account_request: AddAccountRequest, options?: any) {
        return AccountsApiFp(this.configuration).addAccount(add_account_request, options)(this.fetch, this.basePath);
    }

    /**
     * delete account for exchange
     * @summary delete account for exchange
     * @param {string} exchange symbol of the exchange
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public deleteAccount(exchange: string, options?: any) {
        return AccountsApiFp(this.configuration).deleteAccount(exchange, options)(this.fetch, this.basePath);
    }

    /**
     * get account for exchange
     * @summary get account for exchange
     * @param {string} exchange symbol of the exchange
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getAccount(exchange: string, options?: any) {
        return AccountsApiFp(this.configuration).getAccount(exchange, options)(this.fetch, this.basePath);
    }

    /**
     * get a list of api keys
     * @summary get a list of api keys
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public getAccounts(options?: any) {
        return AccountsApiFp(this.configuration).getAccounts(options)(this.fetch, this.basePath);
    }

    /**
     * update account for exchange
     * @summary update account for exchange
     * @param {string} exchange symbol of the exchange
     * @param {UpdateAccountRequest} update_account_request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountsApi
     */
    public updateAccount(exchange: string, update_account_request: UpdateAccountRequest, options?: any) {
        return AccountsApiFp(this.configuration).updateAccount(exchange, update_account_request, options)(this.fetch, this.basePath);
    }

}

/**
 * AlertsApi - fetch parameter creator
 * @export
 */
export const AlertsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * add alert
         * @summary add alert
         * @param {AddAlertRequest} add_alert_request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAlert(add_alert_request: AddAlertRequest, options: any = {}): FetchArgs {
            // verify required parameter 'add_alert_request' is not null or undefined
            if (add_alert_request === null || add_alert_request === undefined) {
                throw new RequiredError('add_alert_request','Required parameter add_alert_request was null or undefined when calling addAlert.');
            }
            const localVarPath = `/alerts`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("OAuth2", ["openid"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddAlertRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(add_alert_request || {}) : (add_alert_request || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * cancel order
         * @summary cancel order
         * @param {string} alert_id id of the alert
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlert(alert_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'alert_id' is not null or undefined
            if (alert_id === null || alert_id === undefined) {
                throw new RequiredError('alert_id','Required parameter alert_id was null or undefined when calling deleteAlert.');
            }
            const localVarPath = `/alerts/{alert_id}`
                .replace(`{${"alert_id"}}`, encodeURIComponent(String(alert_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("OAuth2", ["openid"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {string} alert_id id of the alert
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlert(alert_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'alert_id' is not null or undefined
            if (alert_id === null || alert_id === undefined) {
                throw new RequiredError('alert_id','Required parameter alert_id was null or undefined when calling getAlert.');
            }
            const localVarPath = `/alerts/{alert_id}`
                .replace(`{${"alert_id"}}`, encodeURIComponent(String(alert_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("OAuth2", ["openid"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {string} [exchange] symbol of the exchange
         * @param {string} [base_currency] symbol of the market&#39;s base currency
         * @param {string} [quote_currency] symbol of the market&#39;s quote currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlerts(exchange?: string, base_currency?: string, quote_currency?: string, options: any = {}): FetchArgs {
            const localVarPath = `/alerts`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("OAuth2", ["openid"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (exchange !== undefined) {
                localVarQueryParameter['exchange'] = exchange;
            }

            if (base_currency !== undefined) {
                localVarQueryParameter['base_currency'] = base_currency;
            }

            if (quote_currency !== undefined) {
                localVarQueryParameter['quote_currency'] = quote_currency;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update order
         * @summary update order
         * @param {string} alert_id id of the alert
         * @param {UpdateAlertRequest} update_alert_request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAlert(alert_id: string, update_alert_request: UpdateAlertRequest, options: any = {}): FetchArgs {
            // verify required parameter 'alert_id' is not null or undefined
            if (alert_id === null || alert_id === undefined) {
                throw new RequiredError('alert_id','Required parameter alert_id was null or undefined when calling updateAlert.');
            }
            // verify required parameter 'update_alert_request' is not null or undefined
            if (update_alert_request === null || update_alert_request === undefined) {
                throw new RequiredError('update_alert_request','Required parameter update_alert_request was null or undefined when calling updateAlert.');
            }
            const localVarPath = `/alerts/{alert_id}`
                .replace(`{${"alert_id"}}`, encodeURIComponent(String(alert_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("OAuth2", ["openid"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateAlertRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(update_alert_request || {}) : (update_alert_request || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AlertsApi - functional programming interface
 * @export
 */
export const AlertsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * add alert
         * @summary add alert
         * @param {AddAlertRequest} add_alert_request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAlert(add_alert_request: AddAlertRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiAlert> {
            const localVarFetchArgs = AlertsApiFetchParamCreator(configuration).addAlert(add_alert_request, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * cancel order
         * @summary cancel order
         * @param {string} alert_id id of the alert
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlert(alert_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiAlert> {
            const localVarFetchArgs = AlertsApiFetchParamCreator(configuration).deleteAlert(alert_id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {string} alert_id id of the alert
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlert(alert_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiAlert> {
            const localVarFetchArgs = AlertsApiFetchParamCreator(configuration).getAlert(alert_id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {string} [exchange] symbol of the exchange
         * @param {string} [base_currency] symbol of the market&#39;s base currency
         * @param {string} [quote_currency] symbol of the market&#39;s quote currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlerts(exchange?: string, base_currency?: string, quote_currency?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ApiAlert>> {
            const localVarFetchArgs = AlertsApiFetchParamCreator(configuration).getAlerts(exchange, base_currency, quote_currency, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * update order
         * @summary update order
         * @param {string} alert_id id of the alert
         * @param {UpdateAlertRequest} update_alert_request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAlert(alert_id: string, update_alert_request: UpdateAlertRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiAlert> {
            const localVarFetchArgs = AlertsApiFetchParamCreator(configuration).updateAlert(alert_id, update_alert_request, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AlertsApi - factory interface
 * @export
 */
export const AlertsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * add alert
         * @summary add alert
         * @param {AddAlertRequest} add_alert_request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addAlert(add_alert_request: AddAlertRequest, options?: any) {
            return AlertsApiFp(configuration).addAlert(add_alert_request, options)(fetch, basePath);
        },
        /**
         * cancel order
         * @summary cancel order
         * @param {string} alert_id id of the alert
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAlert(alert_id: string, options?: any) {
            return AlertsApiFp(configuration).deleteAlert(alert_id, options)(fetch, basePath);
        },
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {string} alert_id id of the alert
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlert(alert_id: string, options?: any) {
            return AlertsApiFp(configuration).getAlert(alert_id, options)(fetch, basePath);
        },
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {string} [exchange] symbol of the exchange
         * @param {string} [base_currency] symbol of the market&#39;s base currency
         * @param {string} [quote_currency] symbol of the market&#39;s quote currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAlerts(exchange?: string, base_currency?: string, quote_currency?: string, options?: any) {
            return AlertsApiFp(configuration).getAlerts(exchange, base_currency, quote_currency, options)(fetch, basePath);
        },
        /**
         * update order
         * @summary update order
         * @param {string} alert_id id of the alert
         * @param {UpdateAlertRequest} update_alert_request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAlert(alert_id: string, update_alert_request: UpdateAlertRequest, options?: any) {
            return AlertsApiFp(configuration).updateAlert(alert_id, update_alert_request, options)(fetch, basePath);
        },
    };
};

/**
 * AlertsApi - object-oriented interface
 * @export
 * @class AlertsApi
 * @extends {BaseAPI}
 */
export class AlertsApi extends BaseAPI {
    /**
     * add alert
     * @summary add alert
     * @param {AddAlertRequest} add_alert_request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertsApi
     */
    public addAlert(add_alert_request: AddAlertRequest, options?: any) {
        return AlertsApiFp(this.configuration).addAlert(add_alert_request, options)(this.fetch, this.basePath);
    }

    /**
     * cancel order
     * @summary cancel order
     * @param {string} alert_id id of the alert
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertsApi
     */
    public deleteAlert(alert_id: string, options?: any) {
        return AlertsApiFp(this.configuration).deleteAlert(alert_id, options)(this.fetch, this.basePath);
    }

    /**
     * get a list of api keys
     * @summary get a list of api keys
     * @param {string} alert_id id of the alert
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertsApi
     */
    public getAlert(alert_id: string, options?: any) {
        return AlertsApiFp(this.configuration).getAlert(alert_id, options)(this.fetch, this.basePath);
    }

    /**
     * get a list of api keys
     * @summary get a list of api keys
     * @param {string} [exchange] symbol of the exchange
     * @param {string} [base_currency] symbol of the market&#39;s base currency
     * @param {string} [quote_currency] symbol of the market&#39;s quote currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertsApi
     */
    public getAlerts(exchange?: string, base_currency?: string, quote_currency?: string, options?: any) {
        return AlertsApiFp(this.configuration).getAlerts(exchange, base_currency, quote_currency, options)(this.fetch, this.basePath);
    }

    /**
     * update order
     * @summary update order
     * @param {string} alert_id id of the alert
     * @param {UpdateAlertRequest} update_alert_request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AlertsApi
     */
    public updateAlert(alert_id: string, update_alert_request: UpdateAlertRequest, options?: any) {
        return AlertsApiFp(this.configuration).updateAlert(alert_id, update_alert_request, options)(this.fetch, this.basePath);
    }

}

/**
 * BalancesApi - fetch parameter creator
 * @export
 */
export const BalancesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {string} [exchange] symbol of the exchange
         * @param {string} [currency] symbol of the currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalances(exchange?: string, currency?: string, options: any = {}): FetchArgs {
            const localVarPath = `/balances`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("OAuth2", ["openid"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (exchange !== undefined) {
                localVarQueryParameter['exchange'] = exchange;
            }

            if (currency !== undefined) {
                localVarQueryParameter['currency'] = currency;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BalancesApi - functional programming interface
 * @export
 */
export const BalancesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {string} [exchange] symbol of the exchange
         * @param {string} [currency] symbol of the currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalances(exchange?: string, currency?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ApiBalance>> {
            const localVarFetchArgs = BalancesApiFetchParamCreator(configuration).getBalances(exchange, currency, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * BalancesApi - factory interface
 * @export
 */
export const BalancesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {string} [exchange] symbol of the exchange
         * @param {string} [currency] symbol of the currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBalances(exchange?: string, currency?: string, options?: any) {
            return BalancesApiFp(configuration).getBalances(exchange, currency, options)(fetch, basePath);
        },
    };
};

/**
 * BalancesApi - object-oriented interface
 * @export
 * @class BalancesApi
 * @extends {BaseAPI}
 */
export class BalancesApi extends BaseAPI {
    /**
     * get a list of api keys
     * @summary get a list of api keys
     * @param {string} [exchange] symbol of the exchange
     * @param {string} [currency] symbol of the currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BalancesApi
     */
    public getBalances(exchange?: string, currency?: string, options?: any) {
        return BalancesApiFp(this.configuration).getBalances(exchange, currency, options)(this.fetch, this.basePath);
    }

}

/**
 * ExchangesApi - fetch parameter creator
 * @export
 */
export const ExchangesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get a specific exchange
         * @summary get a specific exchange
         * @param {string} exchange symbol of the exchange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExchange(exchange: string, options: any = {}): FetchArgs {
            // verify required parameter 'exchange' is not null or undefined
            if (exchange === null || exchange === undefined) {
                throw new RequiredError('exchange','Required parameter exchange was null or undefined when calling getExchange.');
            }
            const localVarPath = `/exchanges/{exchange}`
                .replace(`{${"exchange"}}`, encodeURIComponent(String(exchange)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get a list of exchanges
         * @summary get a list of exchanges
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExchanges(options: any = {}): FetchArgs {
            const localVarPath = `/exchanges`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExchangesApi - functional programming interface
 * @export
 */
export const ExchangesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * get a specific exchange
         * @summary get a specific exchange
         * @param {string} exchange symbol of the exchange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExchange(exchange: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiExchange> {
            const localVarFetchArgs = ExchangesApiFetchParamCreator(configuration).getExchange(exchange, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * get a list of exchanges
         * @summary get a list of exchanges
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExchanges(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ApiExchange>> {
            const localVarFetchArgs = ExchangesApiFetchParamCreator(configuration).getExchanges(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ExchangesApi - factory interface
 * @export
 */
export const ExchangesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * get a specific exchange
         * @summary get a specific exchange
         * @param {string} exchange symbol of the exchange
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExchange(exchange: string, options?: any) {
            return ExchangesApiFp(configuration).getExchange(exchange, options)(fetch, basePath);
        },
        /**
         * get a list of exchanges
         * @summary get a list of exchanges
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExchanges(options?: any) {
            return ExchangesApiFp(configuration).getExchanges(options)(fetch, basePath);
        },
    };
};

/**
 * ExchangesApi - object-oriented interface
 * @export
 * @class ExchangesApi
 * @extends {BaseAPI}
 */
export class ExchangesApi extends BaseAPI {
    /**
     * get a specific exchange
     * @summary get a specific exchange
     * @param {string} exchange symbol of the exchange
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangesApi
     */
    public getExchange(exchange: string, options?: any) {
        return ExchangesApiFp(this.configuration).getExchange(exchange, options)(this.fetch, this.basePath);
    }

    /**
     * get a list of exchanges
     * @summary get a list of exchanges
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExchangesApi
     */
    public getExchanges(options?: any) {
        return ExchangesApiFp(this.configuration).getExchanges(options)(this.fetch, this.basePath);
    }

}

/**
 * FillsApi - fetch parameter creator
 * @export
 */
export const FillsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {string} [exchange] symbol of the exchange
         * @param {string} [base_currency] symbol of the market&#39;s base currency
         * @param {string} [quote_currency] symbol of the market&#39;s quote currency
         * @param {string} [after] next page iterator for paginated API&#39;s
         * @param {string} [before] previous page iterator for paginated API&#39;s
         * @param {number} [count] number of results for paginated API&#39;s
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFills(exchange?: string, base_currency?: string, quote_currency?: string, after?: string, before?: string, count?: number, options: any = {}): FetchArgs {
            const localVarPath = `/fills`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("OAuth2", ["openid"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (exchange !== undefined) {
                localVarQueryParameter['exchange'] = exchange;
            }

            if (base_currency !== undefined) {
                localVarQueryParameter['base_currency'] = base_currency;
            }

            if (quote_currency !== undefined) {
                localVarQueryParameter['quote_currency'] = quote_currency;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FillsApi - functional programming interface
 * @export
 */
export const FillsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {string} [exchange] symbol of the exchange
         * @param {string} [base_currency] symbol of the market&#39;s base currency
         * @param {string} [quote_currency] symbol of the market&#39;s quote currency
         * @param {string} [after] next page iterator for paginated API&#39;s
         * @param {string} [before] previous page iterator for paginated API&#39;s
         * @param {number} [count] number of results for paginated API&#39;s
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFills(exchange?: string, base_currency?: string, quote_currency?: string, after?: string, before?: string, count?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ApiFill>> {
            const localVarFetchArgs = FillsApiFetchParamCreator(configuration).getFills(exchange, base_currency, quote_currency, after, before, count, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * FillsApi - factory interface
 * @export
 */
export const FillsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {string} [exchange] symbol of the exchange
         * @param {string} [base_currency] symbol of the market&#39;s base currency
         * @param {string} [quote_currency] symbol of the market&#39;s quote currency
         * @param {string} [after] next page iterator for paginated API&#39;s
         * @param {string} [before] previous page iterator for paginated API&#39;s
         * @param {number} [count] number of results for paginated API&#39;s
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFills(exchange?: string, base_currency?: string, quote_currency?: string, after?: string, before?: string, count?: number, options?: any) {
            return FillsApiFp(configuration).getFills(exchange, base_currency, quote_currency, after, before, count, options)(fetch, basePath);
        },
    };
};

/**
 * FillsApi - object-oriented interface
 * @export
 * @class FillsApi
 * @extends {BaseAPI}
 */
export class FillsApi extends BaseAPI {
    /**
     * get a list of api keys
     * @summary get a list of api keys
     * @param {string} [exchange] symbol of the exchange
     * @param {string} [base_currency] symbol of the market&#39;s base currency
     * @param {string} [quote_currency] symbol of the market&#39;s quote currency
     * @param {string} [after] next page iterator for paginated API&#39;s
     * @param {string} [before] previous page iterator for paginated API&#39;s
     * @param {number} [count] number of results for paginated API&#39;s
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FillsApi
     */
    public getFills(exchange?: string, base_currency?: string, quote_currency?: string, after?: string, before?: string, count?: number, options?: any) {
        return FillsApiFp(this.configuration).getFills(exchange, base_currency, quote_currency, after, before, count, options)(this.fetch, this.basePath);
    }

}

/**
 * MarketsApi - fetch parameter creator
 * @export
 */
export const MarketsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Get a list of open orders for a product.
         * @summary Get Product Order Book
         * @param {string} exchange symbol of the exchange
         * @param {string} base_currency symbol of the market&#39;s base currency
         * @param {string} quote_currency symbol of the market&#39;s quote currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBook(exchange: string, base_currency: string, quote_currency: string, options: any = {}): FetchArgs {
            // verify required parameter 'exchange' is not null or undefined
            if (exchange === null || exchange === undefined) {
                throw new RequiredError('exchange','Required parameter exchange was null or undefined when calling getBook.');
            }
            // verify required parameter 'base_currency' is not null or undefined
            if (base_currency === null || base_currency === undefined) {
                throw new RequiredError('base_currency','Required parameter base_currency was null or undefined when calling getBook.');
            }
            // verify required parameter 'quote_currency' is not null or undefined
            if (quote_currency === null || quote_currency === undefined) {
                throw new RequiredError('quote_currency','Required parameter quote_currency was null or undefined when calling getBook.');
            }
            const localVarPath = `/markets/{exchange}/{base_currency}/{quote_currency}/book`
                .replace(`{${"exchange"}}`, encodeURIComponent(String(exchange)))
                .replace(`{${"base_currency"}}`, encodeURIComponent(String(base_currency)))
                .replace(`{${"quote_currency"}}`, encodeURIComponent(String(quote_currency)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Historic rates for a product. Rates are returned in grouped buckets based on requested interval.  &nbsp;  Candles may be incomplete. No data is published for intervals where there are no ticks.  &nbsp;  Candles should not be polled frequently. If you need real-time information, use the trade and book endpoints along with the websocket feed.  &nbsp;  If either one of the start or end fields are not provided then both fields will be ignored. If a custom time range is not declared then one ending now is selected.  &nbsp;  The maximum number of data points for a single request is 300 candles. If your selection of start/end time and granularity will result in more than 300 data points, your request will be rejected. If you wish to retrieve fine granularity data over a larger time range, you will need to make multiple requests with new start/end ranges.  # RESPONSE ITEMS Each bucket is an array of the following information: * __time__ bucket start time * __low__ lowest price during the bucket interval * __high__ highest price during the bucket interval * __open__ opening price (first trade) in the bucket interval * __close__ closing price (last trade) in the bucket interval * __volume__ volume of trading activity during the bucket interval 
         * @summary Get Historic Rates
         * @param {string} exchange symbol of the exchange
         * @param {string} base_currency symbol of the market&#39;s base currency
         * @param {string} quote_currency symbol of the market&#39;s quote currency
         * @param {ApiCandlestickInterval} [interval] User ID
         * @param {number} [start] User ID
         * @param {number} [end] User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandles(exchange: string, base_currency: string, quote_currency: string, interval?: ApiCandlestickInterval, start?: number, end?: number, options: any = {}): FetchArgs {
            // verify required parameter 'exchange' is not null or undefined
            if (exchange === null || exchange === undefined) {
                throw new RequiredError('exchange','Required parameter exchange was null or undefined when calling getCandles.');
            }
            // verify required parameter 'base_currency' is not null or undefined
            if (base_currency === null || base_currency === undefined) {
                throw new RequiredError('base_currency','Required parameter base_currency was null or undefined when calling getCandles.');
            }
            // verify required parameter 'quote_currency' is not null or undefined
            if (quote_currency === null || quote_currency === undefined) {
                throw new RequiredError('quote_currency','Required parameter quote_currency was null or undefined when calling getCandles.');
            }
            const localVarPath = `/markets/{exchange}/{base_currency}/{quote_currency}/candles`
                .replace(`{${"exchange"}}`, encodeURIComponent(String(exchange)))
                .replace(`{${"base_currency"}}`, encodeURIComponent(String(base_currency)))
                .replace(`{${"quote_currency"}}`, encodeURIComponent(String(quote_currency)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (interval !== undefined) {
                localVarQueryParameter['interval'] = interval;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary get a specific market
         * @param {string} exchange symbol of the exchange
         * @param {string} base_currency symbol of the market&#39;s base currency
         * @param {string} quote_currency symbol of the market&#39;s quote currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarket(exchange: string, base_currency: string, quote_currency: string, options: any = {}): FetchArgs {
            // verify required parameter 'exchange' is not null or undefined
            if (exchange === null || exchange === undefined) {
                throw new RequiredError('exchange','Required parameter exchange was null or undefined when calling getMarket.');
            }
            // verify required parameter 'base_currency' is not null or undefined
            if (base_currency === null || base_currency === undefined) {
                throw new RequiredError('base_currency','Required parameter base_currency was null or undefined when calling getMarket.');
            }
            // verify required parameter 'quote_currency' is not null or undefined
            if (quote_currency === null || quote_currency === undefined) {
                throw new RequiredError('quote_currency','Required parameter quote_currency was null or undefined when calling getMarket.');
            }
            const localVarPath = `/markets/{exchange}/{base_currency}/{quote_currency}`
                .replace(`{${"exchange"}}`, encodeURIComponent(String(exchange)))
                .replace(`{${"base_currency"}}`, encodeURIComponent(String(base_currency)))
                .replace(`{${"quote_currency"}}`, encodeURIComponent(String(quote_currency)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get a list of markets for given exchange
         * @summary get a list of markets for given exchange
         * @param {string} [exchange] symbol of the exchange
         * @param {string} [base_currency] symbol of the market&#39;s base currency
         * @param {string} [quote_currency] symbol of the market&#39;s quote currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarkets(exchange?: string, base_currency?: string, quote_currency?: string, options: any = {}): FetchArgs {
            const localVarPath = `/markets`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (exchange !== undefined) {
                localVarQueryParameter['exchange'] = exchange;
            }

            if (base_currency !== undefined) {
                localVarQueryParameter['base_currency'] = base_currency;
            }

            if (quote_currency !== undefined) {
                localVarQueryParameter['quote_currency'] = quote_currency;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Get 24 hr stats for the product. volume is in base currency units. open, high, low are in quote currency units.
         * @summary Get 24hr Stats
         * @param {string} exchange symbol of the exchange
         * @param {string} base_currency symbol of the market&#39;s base currency
         * @param {string} quote_currency symbol of the market&#39;s quote currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStats(exchange: string, base_currency: string, quote_currency: string, options: any = {}): FetchArgs {
            // verify required parameter 'exchange' is not null or undefined
            if (exchange === null || exchange === undefined) {
                throw new RequiredError('exchange','Required parameter exchange was null or undefined when calling getStats.');
            }
            // verify required parameter 'base_currency' is not null or undefined
            if (base_currency === null || base_currency === undefined) {
                throw new RequiredError('base_currency','Required parameter base_currency was null or undefined when calling getStats.');
            }
            // verify required parameter 'quote_currency' is not null or undefined
            if (quote_currency === null || quote_currency === undefined) {
                throw new RequiredError('quote_currency','Required parameter quote_currency was null or undefined when calling getStats.');
            }
            const localVarPath = `/markets/{exchange}/{base_currency}/{quote_currency}/stats`
                .replace(`{${"exchange"}}`, encodeURIComponent(String(exchange)))
                .replace(`{${"base_currency"}}`, encodeURIComponent(String(base_currency)))
                .replace(`{${"quote_currency"}}`, encodeURIComponent(String(quote_currency)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Snapshot information about the last trade (tick), best bid/ask and 24h volume. REAL-TIME UPDATES Polling is discouraged in favor of connecting via the websocket stream and listening for match messages. 
         * @summary Get Product Ticker
         * @param {string} exchange symbol of the exchange
         * @param {string} base_currency symbol of the market&#39;s base currency
         * @param {string} quote_currency symbol of the market&#39;s quote currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicker(exchange: string, base_currency: string, quote_currency: string, options: any = {}): FetchArgs {
            // verify required parameter 'exchange' is not null or undefined
            if (exchange === null || exchange === undefined) {
                throw new RequiredError('exchange','Required parameter exchange was null or undefined when calling getTicker.');
            }
            // verify required parameter 'base_currency' is not null or undefined
            if (base_currency === null || base_currency === undefined) {
                throw new RequiredError('base_currency','Required parameter base_currency was null or undefined when calling getTicker.');
            }
            // verify required parameter 'quote_currency' is not null or undefined
            if (quote_currency === null || quote_currency === undefined) {
                throw new RequiredError('quote_currency','Required parameter quote_currency was null or undefined when calling getTicker.');
            }
            const localVarPath = `/markets/{exchange}/{base_currency}/{quote_currency}/ticker`
                .replace(`{${"exchange"}}`, encodeURIComponent(String(exchange)))
                .replace(`{${"base_currency"}}`, encodeURIComponent(String(base_currency)))
                .replace(`{${"quote_currency"}}`, encodeURIComponent(String(quote_currency)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * List the latest 100 trades for a product. # SIDE The trade __side__ indicates the maker order side. The maker order is the order that was open on the order book. buy side indicates a down-tick because the maker was a __BUY__ order and their order was removed. Conversely, __SELL__ side indicates an up-tick. 
         * @summary Get Trades
         * @param {string} exchange symbol of the exchange
         * @param {string} base_currency symbol of the market&#39;s base currency
         * @param {string} quote_currency symbol of the market&#39;s quote currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrades(exchange: string, base_currency: string, quote_currency: string, options: any = {}): FetchArgs {
            // verify required parameter 'exchange' is not null or undefined
            if (exchange === null || exchange === undefined) {
                throw new RequiredError('exchange','Required parameter exchange was null or undefined when calling getTrades.');
            }
            // verify required parameter 'base_currency' is not null or undefined
            if (base_currency === null || base_currency === undefined) {
                throw new RequiredError('base_currency','Required parameter base_currency was null or undefined when calling getTrades.');
            }
            // verify required parameter 'quote_currency' is not null or undefined
            if (quote_currency === null || quote_currency === undefined) {
                throw new RequiredError('quote_currency','Required parameter quote_currency was null or undefined when calling getTrades.');
            }
            const localVarPath = `/markets/{exchange}/{base_currency}/{quote_currency}/trades`
                .replace(`{${"exchange"}}`, encodeURIComponent(String(exchange)))
                .replace(`{${"base_currency"}}`, encodeURIComponent(String(base_currency)))
                .replace(`{${"quote_currency"}}`, encodeURIComponent(String(quote_currency)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MarketsApi - functional programming interface
 * @export
 */
export const MarketsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Get a list of open orders for a product.
         * @summary Get Product Order Book
         * @param {string} exchange symbol of the exchange
         * @param {string} base_currency symbol of the market&#39;s base currency
         * @param {string} quote_currency symbol of the market&#39;s quote currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBook(exchange: string, base_currency: string, quote_currency: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiOrderBook> {
            const localVarFetchArgs = MarketsApiFetchParamCreator(configuration).getBook(exchange, base_currency, quote_currency, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Historic rates for a product. Rates are returned in grouped buckets based on requested interval.  &nbsp;  Candles may be incomplete. No data is published for intervals where there are no ticks.  &nbsp;  Candles should not be polled frequently. If you need real-time information, use the trade and book endpoints along with the websocket feed.  &nbsp;  If either one of the start or end fields are not provided then both fields will be ignored. If a custom time range is not declared then one ending now is selected.  &nbsp;  The maximum number of data points for a single request is 300 candles. If your selection of start/end time and granularity will result in more than 300 data points, your request will be rejected. If you wish to retrieve fine granularity data over a larger time range, you will need to make multiple requests with new start/end ranges.  # RESPONSE ITEMS Each bucket is an array of the following information: * __time__ bucket start time * __low__ lowest price during the bucket interval * __high__ highest price during the bucket interval * __open__ opening price (first trade) in the bucket interval * __close__ closing price (last trade) in the bucket interval * __volume__ volume of trading activity during the bucket interval 
         * @summary Get Historic Rates
         * @param {string} exchange symbol of the exchange
         * @param {string} base_currency symbol of the market&#39;s base currency
         * @param {string} quote_currency symbol of the market&#39;s quote currency
         * @param {ApiCandlestickInterval} [interval] User ID
         * @param {number} [start] User ID
         * @param {number} [end] User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandles(exchange: string, base_currency: string, quote_currency: string, interval?: ApiCandlestickInterval, start?: number, end?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<Array<any>>> {
            const localVarFetchArgs = MarketsApiFetchParamCreator(configuration).getCandles(exchange, base_currency, quote_currency, interval, start, end, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @summary get a specific market
         * @param {string} exchange symbol of the exchange
         * @param {string} base_currency symbol of the market&#39;s base currency
         * @param {string} quote_currency symbol of the market&#39;s quote currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarket(exchange: string, base_currency: string, quote_currency: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiMarket> {
            const localVarFetchArgs = MarketsApiFetchParamCreator(configuration).getMarket(exchange, base_currency, quote_currency, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * get a list of markets for given exchange
         * @summary get a list of markets for given exchange
         * @param {string} [exchange] symbol of the exchange
         * @param {string} [base_currency] symbol of the market&#39;s base currency
         * @param {string} [quote_currency] symbol of the market&#39;s quote currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarkets(exchange?: string, base_currency?: string, quote_currency?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ApiMarket>> {
            const localVarFetchArgs = MarketsApiFetchParamCreator(configuration).getMarkets(exchange, base_currency, quote_currency, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Get 24 hr stats for the product. volume is in base currency units. open, high, low are in quote currency units.
         * @summary Get 24hr Stats
         * @param {string} exchange symbol of the exchange
         * @param {string} base_currency symbol of the market&#39;s base currency
         * @param {string} quote_currency symbol of the market&#39;s quote currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStats(exchange: string, base_currency: string, quote_currency: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiStats> {
            const localVarFetchArgs = MarketsApiFetchParamCreator(configuration).getStats(exchange, base_currency, quote_currency, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Snapshot information about the last trade (tick), best bid/ask and 24h volume. REAL-TIME UPDATES Polling is discouraged in favor of connecting via the websocket stream and listening for match messages. 
         * @summary Get Product Ticker
         * @param {string} exchange symbol of the exchange
         * @param {string} base_currency symbol of the market&#39;s base currency
         * @param {string} quote_currency symbol of the market&#39;s quote currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicker(exchange: string, base_currency: string, quote_currency: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiTicker> {
            const localVarFetchArgs = MarketsApiFetchParamCreator(configuration).getTicker(exchange, base_currency, quote_currency, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * List the latest 100 trades for a product. # SIDE The trade __side__ indicates the maker order side. The maker order is the order that was open on the order book. buy side indicates a down-tick because the maker was a __BUY__ order and their order was removed. Conversely, __SELL__ side indicates an up-tick. 
         * @summary Get Trades
         * @param {string} exchange symbol of the exchange
         * @param {string} base_currency symbol of the market&#39;s base currency
         * @param {string} quote_currency symbol of the market&#39;s quote currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrades(exchange: string, base_currency: string, quote_currency: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ApiTrade>> {
            const localVarFetchArgs = MarketsApiFetchParamCreator(configuration).getTrades(exchange, base_currency, quote_currency, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * MarketsApi - factory interface
 * @export
 */
export const MarketsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Get a list of open orders for a product.
         * @summary Get Product Order Book
         * @param {string} exchange symbol of the exchange
         * @param {string} base_currency symbol of the market&#39;s base currency
         * @param {string} quote_currency symbol of the market&#39;s quote currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBook(exchange: string, base_currency: string, quote_currency: string, options?: any) {
            return MarketsApiFp(configuration).getBook(exchange, base_currency, quote_currency, options)(fetch, basePath);
        },
        /**
         * Historic rates for a product. Rates are returned in grouped buckets based on requested interval.  &nbsp;  Candles may be incomplete. No data is published for intervals where there are no ticks.  &nbsp;  Candles should not be polled frequently. If you need real-time information, use the trade and book endpoints along with the websocket feed.  &nbsp;  If either one of the start or end fields are not provided then both fields will be ignored. If a custom time range is not declared then one ending now is selected.  &nbsp;  The maximum number of data points for a single request is 300 candles. If your selection of start/end time and granularity will result in more than 300 data points, your request will be rejected. If you wish to retrieve fine granularity data over a larger time range, you will need to make multiple requests with new start/end ranges.  # RESPONSE ITEMS Each bucket is an array of the following information: * __time__ bucket start time * __low__ lowest price during the bucket interval * __high__ highest price during the bucket interval * __open__ opening price (first trade) in the bucket interval * __close__ closing price (last trade) in the bucket interval * __volume__ volume of trading activity during the bucket interval 
         * @summary Get Historic Rates
         * @param {string} exchange symbol of the exchange
         * @param {string} base_currency symbol of the market&#39;s base currency
         * @param {string} quote_currency symbol of the market&#39;s quote currency
         * @param {ApiCandlestickInterval} [interval] User ID
         * @param {number} [start] User ID
         * @param {number} [end] User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCandles(exchange: string, base_currency: string, quote_currency: string, interval?: ApiCandlestickInterval, start?: number, end?: number, options?: any) {
            return MarketsApiFp(configuration).getCandles(exchange, base_currency, quote_currency, interval, start, end, options)(fetch, basePath);
        },
        /**
         * 
         * @summary get a specific market
         * @param {string} exchange symbol of the exchange
         * @param {string} base_currency symbol of the market&#39;s base currency
         * @param {string} quote_currency symbol of the market&#39;s quote currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarket(exchange: string, base_currency: string, quote_currency: string, options?: any) {
            return MarketsApiFp(configuration).getMarket(exchange, base_currency, quote_currency, options)(fetch, basePath);
        },
        /**
         * get a list of markets for given exchange
         * @summary get a list of markets for given exchange
         * @param {string} [exchange] symbol of the exchange
         * @param {string} [base_currency] symbol of the market&#39;s base currency
         * @param {string} [quote_currency] symbol of the market&#39;s quote currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMarkets(exchange?: string, base_currency?: string, quote_currency?: string, options?: any) {
            return MarketsApiFp(configuration).getMarkets(exchange, base_currency, quote_currency, options)(fetch, basePath);
        },
        /**
         * Get 24 hr stats for the product. volume is in base currency units. open, high, low are in quote currency units.
         * @summary Get 24hr Stats
         * @param {string} exchange symbol of the exchange
         * @param {string} base_currency symbol of the market&#39;s base currency
         * @param {string} quote_currency symbol of the market&#39;s quote currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStats(exchange: string, base_currency: string, quote_currency: string, options?: any) {
            return MarketsApiFp(configuration).getStats(exchange, base_currency, quote_currency, options)(fetch, basePath);
        },
        /**
         * Snapshot information about the last trade (tick), best bid/ask and 24h volume. REAL-TIME UPDATES Polling is discouraged in favor of connecting via the websocket stream and listening for match messages. 
         * @summary Get Product Ticker
         * @param {string} exchange symbol of the exchange
         * @param {string} base_currency symbol of the market&#39;s base currency
         * @param {string} quote_currency symbol of the market&#39;s quote currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTicker(exchange: string, base_currency: string, quote_currency: string, options?: any) {
            return MarketsApiFp(configuration).getTicker(exchange, base_currency, quote_currency, options)(fetch, basePath);
        },
        /**
         * List the latest 100 trades for a product. # SIDE The trade __side__ indicates the maker order side. The maker order is the order that was open on the order book. buy side indicates a down-tick because the maker was a __BUY__ order and their order was removed. Conversely, __SELL__ side indicates an up-tick. 
         * @summary Get Trades
         * @param {string} exchange symbol of the exchange
         * @param {string} base_currency symbol of the market&#39;s base currency
         * @param {string} quote_currency symbol of the market&#39;s quote currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTrades(exchange: string, base_currency: string, quote_currency: string, options?: any) {
            return MarketsApiFp(configuration).getTrades(exchange, base_currency, quote_currency, options)(fetch, basePath);
        },
    };
};

/**
 * MarketsApi - object-oriented interface
 * @export
 * @class MarketsApi
 * @extends {BaseAPI}
 */
export class MarketsApi extends BaseAPI {
    /**
     * Get a list of open orders for a product.
     * @summary Get Product Order Book
     * @param {string} exchange symbol of the exchange
     * @param {string} base_currency symbol of the market&#39;s base currency
     * @param {string} quote_currency symbol of the market&#39;s quote currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public getBook(exchange: string, base_currency: string, quote_currency: string, options?: any) {
        return MarketsApiFp(this.configuration).getBook(exchange, base_currency, quote_currency, options)(this.fetch, this.basePath);
    }

    /**
     * Historic rates for a product. Rates are returned in grouped buckets based on requested interval.  &nbsp;  Candles may be incomplete. No data is published for intervals where there are no ticks.  &nbsp;  Candles should not be polled frequently. If you need real-time information, use the trade and book endpoints along with the websocket feed.  &nbsp;  If either one of the start or end fields are not provided then both fields will be ignored. If a custom time range is not declared then one ending now is selected.  &nbsp;  The maximum number of data points for a single request is 300 candles. If your selection of start/end time and granularity will result in more than 300 data points, your request will be rejected. If you wish to retrieve fine granularity data over a larger time range, you will need to make multiple requests with new start/end ranges.  # RESPONSE ITEMS Each bucket is an array of the following information: * __time__ bucket start time * __low__ lowest price during the bucket interval * __high__ highest price during the bucket interval * __open__ opening price (first trade) in the bucket interval * __close__ closing price (last trade) in the bucket interval * __volume__ volume of trading activity during the bucket interval 
     * @summary Get Historic Rates
     * @param {string} exchange symbol of the exchange
     * @param {string} base_currency symbol of the market&#39;s base currency
     * @param {string} quote_currency symbol of the market&#39;s quote currency
     * @param {ApiCandlestickInterval} [interval] User ID
     * @param {number} [start] User ID
     * @param {number} [end] User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public getCandles(exchange: string, base_currency: string, quote_currency: string, interval?: ApiCandlestickInterval, start?: number, end?: number, options?: any) {
        return MarketsApiFp(this.configuration).getCandles(exchange, base_currency, quote_currency, interval, start, end, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @summary get a specific market
     * @param {string} exchange symbol of the exchange
     * @param {string} base_currency symbol of the market&#39;s base currency
     * @param {string} quote_currency symbol of the market&#39;s quote currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public getMarket(exchange: string, base_currency: string, quote_currency: string, options?: any) {
        return MarketsApiFp(this.configuration).getMarket(exchange, base_currency, quote_currency, options)(this.fetch, this.basePath);
    }

    /**
     * get a list of markets for given exchange
     * @summary get a list of markets for given exchange
     * @param {string} [exchange] symbol of the exchange
     * @param {string} [base_currency] symbol of the market&#39;s base currency
     * @param {string} [quote_currency] symbol of the market&#39;s quote currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public getMarkets(exchange?: string, base_currency?: string, quote_currency?: string, options?: any) {
        return MarketsApiFp(this.configuration).getMarkets(exchange, base_currency, quote_currency, options)(this.fetch, this.basePath);
    }

    /**
     * Get 24 hr stats for the product. volume is in base currency units. open, high, low are in quote currency units.
     * @summary Get 24hr Stats
     * @param {string} exchange symbol of the exchange
     * @param {string} base_currency symbol of the market&#39;s base currency
     * @param {string} quote_currency symbol of the market&#39;s quote currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public getStats(exchange: string, base_currency: string, quote_currency: string, options?: any) {
        return MarketsApiFp(this.configuration).getStats(exchange, base_currency, quote_currency, options)(this.fetch, this.basePath);
    }

    /**
     * Snapshot information about the last trade (tick), best bid/ask and 24h volume. REAL-TIME UPDATES Polling is discouraged in favor of connecting via the websocket stream and listening for match messages. 
     * @summary Get Product Ticker
     * @param {string} exchange symbol of the exchange
     * @param {string} base_currency symbol of the market&#39;s base currency
     * @param {string} quote_currency symbol of the market&#39;s quote currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public getTicker(exchange: string, base_currency: string, quote_currency: string, options?: any) {
        return MarketsApiFp(this.configuration).getTicker(exchange, base_currency, quote_currency, options)(this.fetch, this.basePath);
    }

    /**
     * List the latest 100 trades for a product. # SIDE The trade __side__ indicates the maker order side. The maker order is the order that was open on the order book. buy side indicates a down-tick because the maker was a __BUY__ order and their order was removed. Conversely, __SELL__ side indicates an up-tick. 
     * @summary Get Trades
     * @param {string} exchange symbol of the exchange
     * @param {string} base_currency symbol of the market&#39;s base currency
     * @param {string} quote_currency symbol of the market&#39;s quote currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MarketsApi
     */
    public getTrades(exchange: string, base_currency: string, quote_currency: string, options?: any) {
        return MarketsApiFp(this.configuration).getTrades(exchange, base_currency, quote_currency, options)(this.fetch, this.basePath);
    }

}

/**
 * NotificationsApi - fetch parameter creator
 * @export
 */
export const NotificationsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {string} notification_id id of the notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotification(notification_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'notification_id' is not null or undefined
            if (notification_id === null || notification_id === undefined) {
                throw new RequiredError('notification_id','Required parameter notification_id was null or undefined when calling getNotification.');
            }
            const localVarPath = `/notifications/{notification_id}`
                .replace(`{${"notification_id"}}`, encodeURIComponent(String(notification_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("OAuth2", ["openid"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {string} [exchange] symbol of the exchange
         * @param {string} [base_currency] symbol of the market&#39;s base currency
         * @param {string} [quote_currency] symbol of the market&#39;s quote currency
         * @param {string} [after] next page iterator for paginated API&#39;s
         * @param {string} [before] previous page iterator for paginated API&#39;s
         * @param {number} [count] number of results for paginated API&#39;s
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications(exchange?: string, base_currency?: string, quote_currency?: string, after?: string, before?: string, count?: number, options: any = {}): FetchArgs {
            const localVarPath = `/notifications`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("OAuth2", ["openid"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (exchange !== undefined) {
                localVarQueryParameter['exchange'] = exchange;
            }

            if (base_currency !== undefined) {
                localVarQueryParameter['base_currency'] = base_currency;
            }

            if (quote_currency !== undefined) {
                localVarQueryParameter['quote_currency'] = quote_currency;
            }

            if (after !== undefined) {
                localVarQueryParameter['after'] = after;
            }

            if (before !== undefined) {
                localVarQueryParameter['before'] = before;
            }

            if (count !== undefined) {
                localVarQueryParameter['count'] = count;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update order
         * @summary update order
         * @param {string} notification_id id of the notification
         * @param {UpdateOrderRequest} update_order_request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotifications(notification_id: string, update_order_request: UpdateOrderRequest, options: any = {}): FetchArgs {
            // verify required parameter 'notification_id' is not null or undefined
            if (notification_id === null || notification_id === undefined) {
                throw new RequiredError('notification_id','Required parameter notification_id was null or undefined when calling updateNotifications.');
            }
            // verify required parameter 'update_order_request' is not null or undefined
            if (update_order_request === null || update_order_request === undefined) {
                throw new RequiredError('update_order_request','Required parameter update_order_request was null or undefined when calling updateNotifications.');
            }
            const localVarPath = `/notifications/{notification_id}`
                .replace(`{${"notification_id"}}`, encodeURIComponent(String(notification_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("OAuth2", ["openid"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateOrderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(update_order_request || {}) : (update_order_request || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NotificationsApi - functional programming interface
 * @export
 */
export const NotificationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {string} notification_id id of the notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotification(notification_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiNotification> {
            const localVarFetchArgs = NotificationsApiFetchParamCreator(configuration).getNotification(notification_id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {string} [exchange] symbol of the exchange
         * @param {string} [base_currency] symbol of the market&#39;s base currency
         * @param {string} [quote_currency] symbol of the market&#39;s quote currency
         * @param {string} [after] next page iterator for paginated API&#39;s
         * @param {string} [before] previous page iterator for paginated API&#39;s
         * @param {number} [count] number of results for paginated API&#39;s
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications(exchange?: string, base_currency?: string, quote_currency?: string, after?: string, before?: string, count?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ApiNotification>> {
            const localVarFetchArgs = NotificationsApiFetchParamCreator(configuration).getNotifications(exchange, base_currency, quote_currency, after, before, count, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * update order
         * @summary update order
         * @param {string} notification_id id of the notification
         * @param {UpdateOrderRequest} update_order_request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotifications(notification_id: string, update_order_request: UpdateOrderRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiNotification> {
            const localVarFetchArgs = NotificationsApiFetchParamCreator(configuration).updateNotifications(notification_id, update_order_request, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * NotificationsApi - factory interface
 * @export
 */
export const NotificationsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {string} notification_id id of the notification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotification(notification_id: string, options?: any) {
            return NotificationsApiFp(configuration).getNotification(notification_id, options)(fetch, basePath);
        },
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {string} [exchange] symbol of the exchange
         * @param {string} [base_currency] symbol of the market&#39;s base currency
         * @param {string} [quote_currency] symbol of the market&#39;s quote currency
         * @param {string} [after] next page iterator for paginated API&#39;s
         * @param {string} [before] previous page iterator for paginated API&#39;s
         * @param {number} [count] number of results for paginated API&#39;s
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNotifications(exchange?: string, base_currency?: string, quote_currency?: string, after?: string, before?: string, count?: number, options?: any) {
            return NotificationsApiFp(configuration).getNotifications(exchange, base_currency, quote_currency, after, before, count, options)(fetch, basePath);
        },
        /**
         * update order
         * @summary update order
         * @param {string} notification_id id of the notification
         * @param {UpdateOrderRequest} update_order_request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateNotifications(notification_id: string, update_order_request: UpdateOrderRequest, options?: any) {
            return NotificationsApiFp(configuration).updateNotifications(notification_id, update_order_request, options)(fetch, basePath);
        },
    };
};

/**
 * NotificationsApi - object-oriented interface
 * @export
 * @class NotificationsApi
 * @extends {BaseAPI}
 */
export class NotificationsApi extends BaseAPI {
    /**
     * get a list of api keys
     * @summary get a list of api keys
     * @param {string} notification_id id of the notification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getNotification(notification_id: string, options?: any) {
        return NotificationsApiFp(this.configuration).getNotification(notification_id, options)(this.fetch, this.basePath);
    }

    /**
     * get a list of api keys
     * @summary get a list of api keys
     * @param {string} [exchange] symbol of the exchange
     * @param {string} [base_currency] symbol of the market&#39;s base currency
     * @param {string} [quote_currency] symbol of the market&#39;s quote currency
     * @param {string} [after] next page iterator for paginated API&#39;s
     * @param {string} [before] previous page iterator for paginated API&#39;s
     * @param {number} [count] number of results for paginated API&#39;s
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public getNotifications(exchange?: string, base_currency?: string, quote_currency?: string, after?: string, before?: string, count?: number, options?: any) {
        return NotificationsApiFp(this.configuration).getNotifications(exchange, base_currency, quote_currency, after, before, count, options)(this.fetch, this.basePath);
    }

    /**
     * update order
     * @summary update order
     * @param {string} notification_id id of the notification
     * @param {UpdateOrderRequest} update_order_request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NotificationsApi
     */
    public updateNotifications(notification_id: string, update_order_request: UpdateOrderRequest, options?: any) {
        return NotificationsApiFp(this.configuration).updateNotifications(notification_id, update_order_request, options)(this.fetch, this.basePath);
    }

}

/**
 * OrdersApi - fetch parameter creator
 * @export
 */
export const OrdersApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * add order
         * @summary add order
         * @param {AddOrderRequest} add_order_request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrder(add_order_request: AddOrderRequest, options: any = {}): FetchArgs {
            // verify required parameter 'add_order_request' is not null or undefined
            if (add_order_request === null || add_order_request === undefined) {
                throw new RequiredError('add_order_request','Required parameter add_order_request was null or undefined when calling addOrder.');
            }
            const localVarPath = `/orders`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("OAuth2", ["openid"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddOrderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(add_order_request || {}) : (add_order_request || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * cancel order
         * @summary cancel order
         * @param {string} order_id id of the order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrder(order_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'order_id' is not null or undefined
            if (order_id === null || order_id === undefined) {
                throw new RequiredError('order_id','Required parameter order_id was null or undefined when calling cancelOrder.');
            }
            const localVarPath = `/orders/{order_id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(order_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("OAuth2", ["openid"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get order
         * @summary get order
         * @param {string} order_id id of the order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrder(order_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'order_id' is not null or undefined
            if (order_id === null || order_id === undefined) {
                throw new RequiredError('order_id','Required parameter order_id was null or undefined when calling getOrder.');
            }
            const localVarPath = `/orders/{order_id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(order_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("OAuth2", ["openid"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {string} [exchange] symbol of the exchange
         * @param {string} [base_currency] symbol of the market&#39;s base currency
         * @param {string} [quote_currency] symbol of the market&#39;s quote currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrders(exchange?: string, base_currency?: string, quote_currency?: string, options: any = {}): FetchArgs {
            const localVarPath = `/orders`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("OAuth2", ["openid"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (exchange !== undefined) {
                localVarQueryParameter['exchange'] = exchange;
            }

            if (base_currency !== undefined) {
                localVarQueryParameter['base_currency'] = base_currency;
            }

            if (quote_currency !== undefined) {
                localVarQueryParameter['quote_currency'] = quote_currency;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update order
         * @summary update order
         * @param {string} order_id id of the order
         * @param {UpdateOrderRequest} update_order_request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrder(order_id: string, update_order_request: UpdateOrderRequest, options: any = {}): FetchArgs {
            // verify required parameter 'order_id' is not null or undefined
            if (order_id === null || order_id === undefined) {
                throw new RequiredError('order_id','Required parameter order_id was null or undefined when calling updateOrder.');
            }
            // verify required parameter 'update_order_request' is not null or undefined
            if (update_order_request === null || update_order_request === undefined) {
                throw new RequiredError('update_order_request','Required parameter update_order_request was null or undefined when calling updateOrder.');
            }
            const localVarPath = `/orders/{order_id}`
                .replace(`{${"order_id"}}`, encodeURIComponent(String(order_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("OAuth2", ["openid"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"UpdateOrderRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(update_order_request || {}) : (update_order_request || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrdersApi - functional programming interface
 * @export
 */
export const OrdersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * add order
         * @summary add order
         * @param {AddOrderRequest} add_order_request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrder(add_order_request: AddOrderRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiOrder> {
            const localVarFetchArgs = OrdersApiFetchParamCreator(configuration).addOrder(add_order_request, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * cancel order
         * @summary cancel order
         * @param {string} order_id id of the order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrder(order_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiOrder> {
            const localVarFetchArgs = OrdersApiFetchParamCreator(configuration).cancelOrder(order_id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * get order
         * @summary get order
         * @param {string} order_id id of the order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrder(order_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiOrder> {
            const localVarFetchArgs = OrdersApiFetchParamCreator(configuration).getOrder(order_id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {string} [exchange] symbol of the exchange
         * @param {string} [base_currency] symbol of the market&#39;s base currency
         * @param {string} [quote_currency] symbol of the market&#39;s quote currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrders(exchange?: string, base_currency?: string, quote_currency?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ApiOrder>> {
            const localVarFetchArgs = OrdersApiFetchParamCreator(configuration).getOrders(exchange, base_currency, quote_currency, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * update order
         * @summary update order
         * @param {string} order_id id of the order
         * @param {UpdateOrderRequest} update_order_request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrder(order_id: string, update_order_request: UpdateOrderRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiOrder> {
            const localVarFetchArgs = OrdersApiFetchParamCreator(configuration).updateOrder(order_id, update_order_request, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * OrdersApi - factory interface
 * @export
 */
export const OrdersApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * add order
         * @summary add order
         * @param {AddOrderRequest} add_order_request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addOrder(add_order_request: AddOrderRequest, options?: any) {
            return OrdersApiFp(configuration).addOrder(add_order_request, options)(fetch, basePath);
        },
        /**
         * cancel order
         * @summary cancel order
         * @param {string} order_id id of the order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelOrder(order_id: string, options?: any) {
            return OrdersApiFp(configuration).cancelOrder(order_id, options)(fetch, basePath);
        },
        /**
         * get order
         * @summary get order
         * @param {string} order_id id of the order
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrder(order_id: string, options?: any) {
            return OrdersApiFp(configuration).getOrder(order_id, options)(fetch, basePath);
        },
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {string} [exchange] symbol of the exchange
         * @param {string} [base_currency] symbol of the market&#39;s base currency
         * @param {string} [quote_currency] symbol of the market&#39;s quote currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrders(exchange?: string, base_currency?: string, quote_currency?: string, options?: any) {
            return OrdersApiFp(configuration).getOrders(exchange, base_currency, quote_currency, options)(fetch, basePath);
        },
        /**
         * update order
         * @summary update order
         * @param {string} order_id id of the order
         * @param {UpdateOrderRequest} update_order_request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrder(order_id: string, update_order_request: UpdateOrderRequest, options?: any) {
            return OrdersApiFp(configuration).updateOrder(order_id, update_order_request, options)(fetch, basePath);
        },
    };
};

/**
 * OrdersApi - object-oriented interface
 * @export
 * @class OrdersApi
 * @extends {BaseAPI}
 */
export class OrdersApi extends BaseAPI {
    /**
     * add order
     * @summary add order
     * @param {AddOrderRequest} add_order_request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public addOrder(add_order_request: AddOrderRequest, options?: any) {
        return OrdersApiFp(this.configuration).addOrder(add_order_request, options)(this.fetch, this.basePath);
    }

    /**
     * cancel order
     * @summary cancel order
     * @param {string} order_id id of the order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public cancelOrder(order_id: string, options?: any) {
        return OrdersApiFp(this.configuration).cancelOrder(order_id, options)(this.fetch, this.basePath);
    }

    /**
     * get order
     * @summary get order
     * @param {string} order_id id of the order
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public getOrder(order_id: string, options?: any) {
        return OrdersApiFp(this.configuration).getOrder(order_id, options)(this.fetch, this.basePath);
    }

    /**
     * get a list of api keys
     * @summary get a list of api keys
     * @param {string} [exchange] symbol of the exchange
     * @param {string} [base_currency] symbol of the market&#39;s base currency
     * @param {string} [quote_currency] symbol of the market&#39;s quote currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public getOrders(exchange?: string, base_currency?: string, quote_currency?: string, options?: any) {
        return OrdersApiFp(this.configuration).getOrders(exchange, base_currency, quote_currency, options)(this.fetch, this.basePath);
    }

    /**
     * update order
     * @summary update order
     * @param {string} order_id id of the order
     * @param {UpdateOrderRequest} update_order_request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrdersApi
     */
    public updateOrder(order_id: string, update_order_request: UpdateOrderRequest, options?: any) {
        return OrdersApiFp(this.configuration).updateOrder(order_id, update_order_request, options)(this.fetch, this.basePath);
    }

}

/**
 * StudiesApi - fetch parameter creator
 * @export
 */
export const StudiesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {AddStudyRequest} add_study_request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStudy(add_study_request: AddStudyRequest, options: any = {}): FetchArgs {
            // verify required parameter 'add_study_request' is not null or undefined
            if (add_study_request === null || add_study_request === undefined) {
                throw new RequiredError('add_study_request','Required parameter add_study_request was null or undefined when calling addStudy.');
            }
            const localVarPath = `/studies/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("OAuth2", ["openid"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddStudyRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(add_study_request || {}) : (add_study_request || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update account for exchange
         * @summary update account for exchange
         * @param {string} study_id id of the study
         * @param {AddStudyShapeRequest} add_study_shape_request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStudyShape(study_id: string, add_study_shape_request: AddStudyShapeRequest, options: any = {}): FetchArgs {
            // verify required parameter 'study_id' is not null or undefined
            if (study_id === null || study_id === undefined) {
                throw new RequiredError('study_id','Required parameter study_id was null or undefined when calling addStudyShape.');
            }
            // verify required parameter 'add_study_shape_request' is not null or undefined
            if (add_study_shape_request === null || add_study_shape_request === undefined) {
                throw new RequiredError('add_study_shape_request','Required parameter add_study_shape_request was null or undefined when calling addStudyShape.');
            }
            const localVarPath = `/studies/{study_id}/shapes`
                .replace(`{${"study_id"}}`, encodeURIComponent(String(study_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("OAuth2", ["openid"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"AddStudyShapeRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(add_study_shape_request || {}) : (add_study_shape_request || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete account for exchange
         * @summary delete account for exchange
         * @param {string} study_id id of the study
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStudy(study_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'study_id' is not null or undefined
            if (study_id === null || study_id === undefined) {
                throw new RequiredError('study_id','Required parameter study_id was null or undefined when calling deleteStudy.');
            }
            const localVarPath = `/studies/{study_id}`
                .replace(`{${"study_id"}}`, encodeURIComponent(String(study_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("OAuth2", ["openid"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {string} [exchange] symbol of the exchange
         * @param {string} [base_currency] symbol of the market&#39;s base currency
         * @param {string} [quote_currency] symbol of the market&#39;s quote currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudies(exchange?: string, base_currency?: string, quote_currency?: string, options: any = {}): FetchArgs {
            const localVarPath = `/studies/`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("OAuth2", ["openid"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (exchange !== undefined) {
                localVarQueryParameter['exchange'] = exchange;
            }

            if (base_currency !== undefined) {
                localVarQueryParameter['base_currency'] = base_currency;
            }

            if (quote_currency !== undefined) {
                localVarQueryParameter['quote_currency'] = quote_currency;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get account for exchange
         * @summary get account for exchange
         * @param {string} study_id id of the study
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudy(study_id: string, options: any = {}): FetchArgs {
            // verify required parameter 'study_id' is not null or undefined
            if (study_id === null || study_id === undefined) {
                throw new RequiredError('study_id','Required parameter study_id was null or undefined when calling getStudy.');
            }
            const localVarPath = `/studies/{study_id}`
                .replace(`{${"study_id"}}`, encodeURIComponent(String(study_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("OAuth2", ["openid"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get account for exchange
         * @summary get account for exchange
         * @param {string} study_id id of the study
         * @param {number} [start] User ID
         * @param {number} [end] User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyShapes(study_id: string, start?: number, end?: number, options: any = {}): FetchArgs {
            // verify required parameter 'study_id' is not null or undefined
            if (study_id === null || study_id === undefined) {
                throw new RequiredError('study_id','Required parameter study_id was null or undefined when calling getStudyShapes.');
            }
            const localVarPath = `/studies/{study_id}/shapes`
                .replace(`{${"study_id"}}`, encodeURIComponent(String(study_id)));
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'GET' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication OAuth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("OAuth2", ["openid"])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (start !== undefined) {
                localVarQueryParameter['start'] = start;
            }

            if (end !== undefined) {
                localVarQueryParameter['end'] = end;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * StudiesApi - functional programming interface
 * @export
 */
export const StudiesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {AddStudyRequest} add_study_request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStudy(add_study_request: AddStudyRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiStudy> {
            const localVarFetchArgs = StudiesApiFetchParamCreator(configuration).addStudy(add_study_request, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * update account for exchange
         * @summary update account for exchange
         * @param {string} study_id id of the study
         * @param {AddStudyShapeRequest} add_study_shape_request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStudyShape(study_id: string, add_study_shape_request: AddStudyShapeRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiStudyShape> {
            const localVarFetchArgs = StudiesApiFetchParamCreator(configuration).addStudyShape(study_id, add_study_shape_request, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * delete account for exchange
         * @summary delete account for exchange
         * @param {string} study_id id of the study
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStudy(study_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiStudy> {
            const localVarFetchArgs = StudiesApiFetchParamCreator(configuration).deleteStudy(study_id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {string} [exchange] symbol of the exchange
         * @param {string} [base_currency] symbol of the market&#39;s base currency
         * @param {string} [quote_currency] symbol of the market&#39;s quote currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudies(exchange?: string, base_currency?: string, quote_currency?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ApiStudy>> {
            const localVarFetchArgs = StudiesApiFetchParamCreator(configuration).getStudies(exchange, base_currency, quote_currency, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * get account for exchange
         * @summary get account for exchange
         * @param {string} study_id id of the study
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudy(study_id: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ApiStudy> {
            const localVarFetchArgs = StudiesApiFetchParamCreator(configuration).getStudy(study_id, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * get account for exchange
         * @summary get account for exchange
         * @param {string} study_id id of the study
         * @param {number} [start] User ID
         * @param {number} [end] User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyShapes(study_id: string, start?: number, end?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ApiStudyShape>> {
            const localVarFetchArgs = StudiesApiFetchParamCreator(configuration).getStudyShapes(study_id, start, end, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * StudiesApi - factory interface
 * @export
 */
export const StudiesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {AddStudyRequest} add_study_request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStudy(add_study_request: AddStudyRequest, options?: any) {
            return StudiesApiFp(configuration).addStudy(add_study_request, options)(fetch, basePath);
        },
        /**
         * update account for exchange
         * @summary update account for exchange
         * @param {string} study_id id of the study
         * @param {AddStudyShapeRequest} add_study_shape_request 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addStudyShape(study_id: string, add_study_shape_request: AddStudyShapeRequest, options?: any) {
            return StudiesApiFp(configuration).addStudyShape(study_id, add_study_shape_request, options)(fetch, basePath);
        },
        /**
         * delete account for exchange
         * @summary delete account for exchange
         * @param {string} study_id id of the study
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteStudy(study_id: string, options?: any) {
            return StudiesApiFp(configuration).deleteStudy(study_id, options)(fetch, basePath);
        },
        /**
         * get a list of api keys
         * @summary get a list of api keys
         * @param {string} [exchange] symbol of the exchange
         * @param {string} [base_currency] symbol of the market&#39;s base currency
         * @param {string} [quote_currency] symbol of the market&#39;s quote currency
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudies(exchange?: string, base_currency?: string, quote_currency?: string, options?: any) {
            return StudiesApiFp(configuration).getStudies(exchange, base_currency, quote_currency, options)(fetch, basePath);
        },
        /**
         * get account for exchange
         * @summary get account for exchange
         * @param {string} study_id id of the study
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudy(study_id: string, options?: any) {
            return StudiesApiFp(configuration).getStudy(study_id, options)(fetch, basePath);
        },
        /**
         * get account for exchange
         * @summary get account for exchange
         * @param {string} study_id id of the study
         * @param {number} [start] User ID
         * @param {number} [end] User ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStudyShapes(study_id: string, start?: number, end?: number, options?: any) {
            return StudiesApiFp(configuration).getStudyShapes(study_id, start, end, options)(fetch, basePath);
        },
    };
};

/**
 * StudiesApi - object-oriented interface
 * @export
 * @class StudiesApi
 * @extends {BaseAPI}
 */
export class StudiesApi extends BaseAPI {
    /**
     * get a list of api keys
     * @summary get a list of api keys
     * @param {AddStudyRequest} add_study_request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudiesApi
     */
    public addStudy(add_study_request: AddStudyRequest, options?: any) {
        return StudiesApiFp(this.configuration).addStudy(add_study_request, options)(this.fetch, this.basePath);
    }

    /**
     * update account for exchange
     * @summary update account for exchange
     * @param {string} study_id id of the study
     * @param {AddStudyShapeRequest} add_study_shape_request 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudiesApi
     */
    public addStudyShape(study_id: string, add_study_shape_request: AddStudyShapeRequest, options?: any) {
        return StudiesApiFp(this.configuration).addStudyShape(study_id, add_study_shape_request, options)(this.fetch, this.basePath);
    }

    /**
     * delete account for exchange
     * @summary delete account for exchange
     * @param {string} study_id id of the study
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudiesApi
     */
    public deleteStudy(study_id: string, options?: any) {
        return StudiesApiFp(this.configuration).deleteStudy(study_id, options)(this.fetch, this.basePath);
    }

    /**
     * get a list of api keys
     * @summary get a list of api keys
     * @param {string} [exchange] symbol of the exchange
     * @param {string} [base_currency] symbol of the market&#39;s base currency
     * @param {string} [quote_currency] symbol of the market&#39;s quote currency
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudiesApi
     */
    public getStudies(exchange?: string, base_currency?: string, quote_currency?: string, options?: any) {
        return StudiesApiFp(this.configuration).getStudies(exchange, base_currency, quote_currency, options)(this.fetch, this.basePath);
    }

    /**
     * get account for exchange
     * @summary get account for exchange
     * @param {string} study_id id of the study
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudiesApi
     */
    public getStudy(study_id: string, options?: any) {
        return StudiesApiFp(this.configuration).getStudy(study_id, options)(this.fetch, this.basePath);
    }

    /**
     * get account for exchange
     * @summary get account for exchange
     * @param {string} study_id id of the study
     * @param {number} [start] User ID
     * @param {number} [end] User ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StudiesApi
     */
    public getStudyShapes(study_id: string, start?: number, end?: number, options?: any) {
        return StudiesApiFp(this.configuration).getStudyShapes(study_id, start, end, options)(this.fetch, this.basePath);
    }

}

