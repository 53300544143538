import * as React from "react";
import {Tt} from "./user";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

export interface Props {
    balances: Array<Tt> | null;
}

interface State {

}

export default class BalancesComponent extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
    }

    render() {
        if (this.props.balances === null) {
            return (
                <div>loading balances...</div>
            );
        } else {
            const balances = this.props.balances.filter(balance => parseFloat(balance.balance.balance) > 0).map(balance =>
                <TableRow key={balance.balance.exchange + "/" + balance.balance.currency}>
                    <TableCell align="center">{balance.balance.exchange}</TableCell>
                    <TableCell align="center">{balance.balance.currency}</TableCell>
                    <TableCell align="right">{balance.balance.balance}</TableCell>
                    <TableCell align="right">{balance.balance.available}</TableCell>
                    <TableCell align="right">{balance.balance.unavailable}</TableCell>
                    <TableCell align="right">{balance.btc}</TableCell>
                </TableRow>
            );
            let total = 0;
            for (const balance of this.props.balances) {
                total = total + balance.btc;
            }
            return (
                <Paper>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>exchange</TableCell>
                                <TableCell>currency</TableCell>
                                <TableCell>balance</TableCell>
                                <TableCell>available</TableCell>
                                <TableCell>unavailable</TableCell>
                                <TableCell>btc</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {balances}
                        </TableBody>
                    </Table>
                </Paper>
            );
        }
    }

}
